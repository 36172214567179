// Mixins

@mixin submenu() {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 360px;
  height: 0;
  overflow: hidden;
  text-align: left;
  background: #fff;
  box-shadow:
    0 0 5px 0 rgba(#000, 0.05),
    0 30px 30px -10px rgba(#000, 0.15);
  border-radius: 20px;
  transition:
    height 0.3s $easeCubic,
    box-shadow 0.3s;

  @include phone() {
    border-radius: 20px;
  }
  .submenu_inner {
    box-sizing: border-box;
    padding: 40px;
    opacity: 0;
    transform: translateY(-20px);
    transition:
      transform 0.3s $easeCubic,
      opacity 0.3s $easeCubic;

    @include mobile_menu() {
      transform: translateY(20px);
      padding: 30px 40px;
      transition-timing-function: $easeCubic, $easeCubic;
    }
    @include phone() {
      padding: 30px;
    }
  }
  form {
    @include form();
    position: relative;
    text-align: right;

    &:after {
      display: none;
    }
  }
  .form-group {
    &:first-of-type {
    }
    + .form-group {
      margin-top: 20px;
    }
    label {
      display: none !important;
    }
  }
  .btn {
    margin-top: 20px;
  }
  a.forgot_password {
    position: absolute;
    bottom: 0;
    left: 0;
    @include p_little($gray_text);
    transition: color 0.3s;

    &:hover {
      color: $orange;
    }
    &:focus-visible {
      box-shadow: 2px 0px 0px $gray;
    }
  }
}
@mixin mobile_site_nav_menu_styles() {
  // nav
  position: fixed;
  z-index: 202;
  top: 0;
  bottom: 0;
  right: 0;
  width: 400px;
  transform: translateX(100%);
  transition:
    transform 0.5s $easeCubic,
    box-shadow 0.5s $easeCubic;

  @include phone() {
    width: calc(100% - 30px);
  }
  .shade {
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(#000, 0.4);
    transform-origin: 100% 0;
    transform: translateX(-100%) scaleX(15);
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;

    @include phone() {
      background: rgba(#000, 0.6);
    }
  }
  .nav_inner {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-top: 60px;
    padding-bottom: 60px;
    box-sizing: border-box;
    background: #fff;
    box-shadow:
      0 0 0 rgba(#000, 0),
      0 0 0 rgba(#000, 0);
    transition:
      transform 0.5s $easeCubic,
      box-shadow 0.5s $easeCubic;

    @include phone() {
    }
    > ul {
      width: 100%;
      font-size: 0;
    }
    > ul > li {
      display: block;
      color: $gray;

      > a,
      > span {
        display: block;
        font-family: $heading_font;
        font-weight: 700;
        font-size: 27px;
        letter-spacing: 0;
        line-height: 1.2;
        padding: 15px 0;
        padding-left: 80px;
        padding-right: 80px;
        transition: background 0.3s;

        @include hover() {
          background: $mint;
        }
        @include phone() {
          padding-left: 50px;
          padding-right: 50px;
        }
      }
      &.homepage {
        > a {
          display: block;
          width: 40px;
          height: 40px;
          padding: 0 !important;
          margin-left: 80px;
          margin-bottom: 25px;
          background: image-url(
              '../../assets/images/layout/logolounge_logo_filled_mono_gray.svg'
            )
            no-repeat 0 0/100% auto;
          @include hide_text();

          @include phone(false) {
            display: none;
          }
          @include phone() {
            margin-left: 50px;
          }
        }
      }
      .submenu {
        position: relative;
        left: 40px;
        width: calc(100% - 80px);

        @include phone() {
          left: 30px;
          width: calc(100% - 60px);
        }
      }
      &.has_submenu {
        > a {
          position: relative;
          pointer-events: none;

          &:before {
            // circle
            content: '';
            position: absolute;
            top: calc(50% - 20px);
            right: 80px - 16px;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            transition: background 0.3s;

            @include phone() {
              right: 60px - 16px;
            }
          }
          &:after {
            // carat
            content: '';
            position: absolute;
            top: calc(50% - 5px);
            right: 80px;
            width: 8px;
            height: 8px;
            border-left: solid 2px $gray;
            border-bottom: solid 2px $gray;
            box-sizing: border-box;
            transform: rotate(-45deg);
            transition: transform 0.3s $easeCubic;

            @include phone() {
              right: 60px;
            }
          }
        }
      }
      &.label {
        pointer-events: none;

        > span {
          @include label();
          padding-top: 37px;
          padding-bottom: 12px;
        }
      }
      &.secondary {
        > a,
        div {
          @include p();
          font-weight: normal;
          padding-top: 10px;
          padding-bottom: 10px;

          &.login_button,
          &.join_button,
          &.logout_button {
            margin: 20px 80px 0 80px;
            padding: 10px 25px;
            text-align: center;
            background: #f2f2f2;
            border-radius: 10px;
            font-weight: 600;
            transition: background 0.3s;

            @include phone() {
              margin-left: 50px;
              margin-right: 50px;
            }
            @include hover() {
              background-color: $mint;
            }
          }
          &.login_button {
          }
          &.join_button {
            color: #fff;
            font-weight: bold;
            background: $orange
              image-url('../../assets/images/layout/orange_button_bg.png') no-repeat 50%
              50%/101% 100%;

            @include hover() {
              background-image: image-url('../../assets/images/layout/transparent.gif');
            }
          }
          &.logout_button {
            background-image: image-url('../../assets/images/icon/x_gray_little.png');
            background-size: 12px auto;
            background-position: calc(100% - 20px) 50%;
            padding-right: 20px + 12px + 25px;
            background-repeat: no-repeat;

            @include hover() {
              background-image: image-url(
                '../../assets/images/icon/x_gray_little.png'
              ) !important;
            }
          }
        }
        &:has(.login_button) {
          width: 50%;
          display: inline-block;

          > a.login_button {
            margin-right: 5px;
          }
        }
        &:has(.join_button) {
          width: 50%;
          display: inline-block;

          > a.join_button {
            margin-left: 5px;
          }
        }
      }
      @include hover() {
        &.has_submenu {
          > a:after {
            // carat
            transform: rotate(135deg) translate(1px, -1px);
          }
        }
      }
    }
  }
}
@mixin hamburger_menu() {
  position: fixed;
  z-index: 203;
  top: 0;
  right: 42px;
  border-radius: 15px;
  overflow: hidden;
  background: image-url('../../assets/images/icon/x_gray.svg') no-repeat 50% 50%/20px auto;
  transition:
    transform 0.3s $easeCubic,
    background 0.3s,
    margin 0.3s $easeCubic,
    top 0.3s $easeCubic,
    right 0.3s $easeCubic;

  @include desktop(false) {
    width: 80px;
    height: 80px;
    transform: translateY(-80px);
  }
  @include desktop() {
    width: 70px;
    height: 70px;
    transform: translateY(-70px);
  }
  @include mobile_menu() {
    width: 60px;
    height: 60px;
    transform: translateY(-60px);
  }
  @include mobile() {
    right: 32px;
  }
  @include phone() {
    right: 12px;
  }
  .a,
  .b,
  .c {
    position: absolute;
    top: 50%;
    right: calc(50% - 11px);
    width: 22px;
    height: 0;
    border-top: solid 3px #fff;
    transition: transform 0.1s steps(3);
  }
  .b {
    transition-delay: 0.5s;
    margin-top: -8px;
    transform-origin: 50% 0;
  }
  .a {
    transition-delay: 0.525s;
    margin-top: -1px;
    transform-origin: 50% 0;
  }
  .c {
    transition-delay: 0.55s;
    transform-origin: 50% 0;
    margin-top: 6px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    border-radius: 10px;
    transition: background 0.3s;
  }
  @include hover() {
    &:before {
      background-color: rgba(#fff, 0.1);
    }
  }
}

// Styles

body {
  padding-top: 80px; // space behind header on desktop

  @include desktop() {
    padding-top: 70px; // space behind header on desktop
  }
  @include mobile_menu() {
    padding-top: 60px; // space behind header on mobile
  }
}
.site-header {
  // header
  position: fixed;
  z-index: 201;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: $gray;
  transform: translateY(-80px);
  transition:
    transform 0.3s $easeCubic,
    box-shadow 0.3s $easeCubic,
    background 0.3s;

  @include desktop() {
    height: 70px;
    transform: translateY(-70px);
  }
  @include mobile_menu() {
    height: 60px;
    transform: translateY(-60px);
  }
  .logolounge_logo {
    // a
    position: absolute;
    @include spacing('left');
    top: 0;
    height: inherit;
    transform-origin: 0 50%;
    font-size: 0;
    cursor: pointer;

    @include mobile() {
      transform: scale(0.8);
    }
    &:before {
      // logo icon
      content: '';
      position: absolute;
      top: calc(50% - 17px);
      left: 0;
      width: 33px;
      height: 33px;
      background: image-url(
          '../../assets/images/layout/logolounge_logo_mono_white_33.svg'
        )
        no-repeat 0 0/100% auto;
    }
    &:after {
      // logo wordmark
      content: '';
      position: absolute;
      top: calc(50% - 10px);
      left: 0;
      margin-left: 43px;
      width: 148px;
      height: 29px;
      background: image-url(
          '../../assets/images/layout/logolounge_logo_wordmark_white.svg'
        )
        no-repeat 0 0/100% auto;
    }
  }
}
body.is_user .site-header {
  @include mobile_menu() {
    position: absolute;
    transform: none !important;
  }
}
.site-navigation {
  // nav

  .submenu {
    @include submenu();
  }

  .nav_inner {
    // ul
    display: block;
    white-space: nowrap;
    margin: 0;

    > ul > li {
      position: relative;
      display: inline-block;
      @include p_article(#fff);

      > a {
        cursor: pointer;
      }
      > a,
      > span {
        display: inherit;
        line-height: 80px;
        font-weight: 600;
        padding: 0 20px;

        @include desktop() {
          line-height: 70px;
        }
        &.active {
          position: relative;

          @include mobile_menu(false) {
            color: $mint;
          }
        }
      }
      .submenu {
        right: auto;
        left: 0;
        top: calc(100% - 10px);

        ul {
          margin: -15px -40px;
        }
        li a {
          display: block;
          padding: 10px 40px;
          color: $gray;
          white-space: nowrap;
          transition:
            color 0.3s,
            background 0.3s;

          &:hover {
            background: $mint;
          }
        }
      }
      &.has_submenu {
        @include hover() {
          > a:before {
            background-color: $mint;
          }
        }
      }
      &:hover {
        .submenu {
          transition:
            height 0.3s $easeCubic,
            box-shadow 0.3s;

          @for $i from 1 through 10 {
            &.item_count-#{$i} {
              height: ($i * 50px) + 50px;

              @include desktop() {
                height: ($i * 47px) + 50px;
              }
              @include mobile_menu() {
                height: ($i * 47px) + 30px;
              }
              @include mobile() {
                height: ($i * 44px) + 30px;
              }
            }
          }
          .submenu_inner {
            opacity: 1;
            transform: translateY(0);
            transition:
              transform 0.3s $easeCubic,
              opacity 0.3s $easeCubic;
          }
        }
      }
    }
  }
}
.site-navigation {
  .nav_inner > ul > li.active {
    a:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 20px;
      height: 4px;
      transform-origin: 0 100%;
      border-radius: 4px 4px 0 0;
      background: $mint image-url('../../assets/images/layout/mint_button_bg.png')
        no-repeat 50% 50%/101% 100%;
      transition: transform 0.2s $easeCubic;

      @include mobile_menu() {
        bottom: calc(50% - 20px);
        left: 0;
        right: auto;
        width: 6px;
        height: 40px;
        border-radius: 0 4px 4px 0;
        background-image: image-url('../../assets/images/layout/mint_vertical.png');
      }
      @include phone() {
      }
    }
  }
}
body.is_user .site-navigation .nav_inner > ul > li.active a:before {
  bottom: calc(50% - 20px);
  left: 0;
  right: auto;
  width: 6px;
  height: 40px;
  border-radius: 0 4px 4px 0;
  background-image: image-url('../../assets/images/layout/mint_vertical.png');

  @include phone() {
  }
}

body:not(.is_user) .site-navigation {
  @include mobile_menu(false) {
    position: fixed;
    z-index: 202;
    top: 0;
    left: calc(50% - 300px);
    width: 600px;
    height: 80px;
    text-align: center;
    transform: translateY(-80px);
    transition: transform 0.3s $easeCubic;

    @include desktop() {
      height: 70px;
      transform: translateY(-70px);
    }
    @include mobile_menu() {
      height: 60px;
      transform: translateY(-60px);
    }
  }
  @include mobile_menu() {
    @include mobile_site_nav_menu_styles();
  }
  .nav_inner {
    > ul > li {
      &.homepage {
        @include phone(false) {
          display: none !important;
        }
      }
      > a,
      > span {
        @include mobile_menu(false) {
          transition: color 0.3s;

          &:hover {
            color: $mint;
          }
        }
      }
      &.label > span,
      &.secondary > a,
      div {
        @include mobile_menu(false) {
          display: none;
        }
      }
    }
  }
}
body.is_user .site-navigation {
  @include mobile_site_nav_menu_styles();

  .nav_inner {
    > ul > li {
      &.label > span:last-child {
      }
      &:not(.secondary) + .secondary {
        border-top: solid 1px $gray_light;
        margin-top: 30px;
        padding-top: 15px;
        padding-bottom: 10px;
      }
    }
  }
}

.user-navigation {
  div:first-child {
    display: block;
  }
  .submenu {
    @include submenu();

    @include mobile_menu() {
      z-index: 4;
      width: calc(100vw - 20px);
      right: 10px;
    }
  }
  .submenu_background {
    @include mobile_menu() {
      position: fixed;
      z-index: 3;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100vh;
      background: rgba(#000, 0.4);
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.5s;
    }
  }
  li {
    display: inline-block;
    vertical-align: middle;

    .submenu_toggle {
      display: none;

      &:checked {
        + * + .submenu {
          transition:
            height 0.3s $easeCubic,
            box-shadow 0.3s;

          @include mobile_menu() {
            height: auto;
          }
          .submenu_inner {
            opacity: 1;
            transform: translateY(0);
            transition:
              transform 0.3s $easeCubic,
              opacity 0.3s $easeCubic;
          }
        }
      }
    }
    &.has_activity_menu {
      .activity_outer {
        position: relative;
        width: 100%;

        .label {
          @include label();
        }
        .button_outer {
          text-align: right;
        }
        .all_button {
          @include button_tiny();
        }
      }
      .submenu_toggle:checked + * + .submenu {
        @include mobile_menu() {
          right: calc(200% - 200px);
          width: 400px;

          @media (max-width: 420px) {
            right: calc(200% - 50vw + 10px);
            width: calc(100vw - 20px);
          }
        }
      }
      .submenu_inner {
        display: flex;
        flex-wrap: wrap-reverse;
      }
    }
  }
  .profile_summary {
    cursor: pointer;
    position: relative;
    @include caption_heading();
    flex-wrap: wrap;

    margin-bottom: 40px;
    padding-bottom: 40px;

    @include mobile_menu() {
      margin-bottom: 30px;
      padding-bottom: 30px;
    }
    .profile_image {
      margin-right: 20px;
    }
    .text {
      width: calc(100% - 70px);
    }
    &:before {
      // line
      content: '';
      position: absolute;
      top: 0;
      left: -40px;
      right: -40px;
      border-top: solid 1px $gray_light;

      top: 100%;
      // }
      @include phone() {
        left: -30px;
        right: -30px;
      }
    }
    &:after {
      content: 'Your Profile';
      width: 100%;
      color: $black;
      font-weight: 600;
      text-align: center;
      border-radius: 10px;
      padding: 10px 25px;
      margin-top: 30px;
      background-color: #f2f2f2;
      background-image: image-url('../../assets/images/layout/arrow_tiny_gray.svg');
      background-size: 8px auto;
      background-position: calc(100% - 20px) 50%;
      padding-right: 20px + 8px + 25px;
      background-repeat: no-repeat;
      transition: background 0.3s;

      @include phone() {
      }
    }
    @include hover() {
      &:after {
        background-color: $mint;
      }
    }
  }
  &:has(.submenu_toggle:checked) .submenu_background {
    pointer-events: auto;
    opacity: 1;
  }
}
body.is_user .user-navigation {
  @include disable_text_selection();

  @include mobile_menu(false) {
    position: fixed;
    z-index: 201;
    top: 0;
    right: 120px + 52px; // Offset for the hamburger menu
    margin-top: 21px;
    font-size: 0;
    transform: translateY(-80px);
    transition:
      transform 0.3s $easeCubic,
      box-shadow 0.3s $easeCubic;

    @include desktop() {
      right: 80px + 47px; // Offset for the hamburger menu
      margin-top: 19px;
      transform: translateY(-70px);
    }
    @include laptop() {
      right: 60px + 47px; // Offset for the hamburger menu
    }
  }
  .submenu {
    @include mobile_menu() {
      top: auto;
      bottom: calc(100% + 10px);
      right: 10px;
      transition-timing-function: $easeCubic;
    }
  }
  > ul {
    @include mobile_menu() {
      position: fixed;
      z-index: 201;
      top: auto;
      bottom: 10px;
      left: 50%;
      right: auto !important;

      font-size: 0;

      margin: auto;
      transform: translate(-50%, 80px);
      transition:
        transform 0.3s $easeCubic,
        box-shadow 0.3s $easeCubic;

      background: $gray;
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 15px;
      white-space: nowrap;
      box-shadow: 0 20px 40px rgba(#000, 0.2);
    }
  }
  li {
    // _button_outer
    position: relative;
    display: inline-block;
    vertical-align: middle;

    @include mobile_menu() {
      width: 70px;
      white-space: normal;
    }
    .explore_button_outer,
    .search_button_outer {
      @include mobile_menu(false) {
        display: none;
      }
    }
    .button {
      // a

      @include mobile_menu() {
        display: block;
        width: 100%;

        span {
          display: block;
          position: relative;
          z-index: 1;
          height: 56px;
          box-sizing: border-box;
          @include hide_text();
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 21px auto;
          opacity: 0.7;
          transition:
            opacity 0.1s,
            color 0.1s,
            background 0.1s $easeCubic;

          @include phone() {
            background-size: 20px auto;
          }
        }
        &:before {
          content: '';
          position: absolute;
          top: 11px;
          height: 34px;
          left: 4px;
          right: 4px;
          border-radius: 100px;
          background: rgba($foam, 0.3);
          opacity: 0;
          transition:
            opacity 0.3s,
            background 0.3s;
          pointer-events: none;
        }
        &:focus,
        &:active {
          // after a tap

          &:before {
            opacity: 1;
          }
        }
        &.active {
          span {
            opacity: 1;
          }
          &:before {
            opacity: 1;
          }
        }
      }
    }
    .explore_button {
      span {
        @include mobile_menu() {
          background-image: image-url('../../assets/images/icon/explore.svg');
        }
      }
      &.active span {
        @include mobile_menu() {
          background-image: image-url('../../assets/images/icon/explore_mint.svg');
        }
      }
    }
    .search_button {
      span {
        @include mobile_menu() {
          background-image: image-url('../../assets/images/icon/search.svg');
        }
      }
      &.active span {
        @include mobile_menu() {
          background-image: image-url('../../assets/images/icon/search_mint.svg');
        }
      }
    }
    .upload_button,
    .activity_menu_toggle_button {
      span {
        @include mobile_menu(false) {
          display: inline-block;
          vertical-align: middle;
          height: 50px;
          font-family: $paragraph_font;
          color: #fff;
          font-weight: 600;
          font-size: 16px;
          line-height: 50px;
          padding: 0 20px 0 51px;
          border-radius: 10px;
          margin: -11px 0;
          background: image-url('../../assets/images/icon/upload.svg') no-repeat 15px 50%/21px
            auto;
          transition: background 0.3s;

          @include desktop(false) {
            height: 60px;
            line-height: 60px;
            background-position-x: 20px;
            padding-left: 56px;
            padding-right: 25px;
          }
          @include hover() {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
    .upload_button {
      span {
        background-image: image-url('../../assets/images/icon/upload.svg');
      }
      &.active span {
        @include mobile_menu() {
          background-image: image-url('../../assets/images/icon/upload_mint.svg');
        }
      }
    }
    .activity_menu_toggle_button {
      &.active span {
        @include mobile_menu() {
        }
      }
    }

    .activity_menu_toggle_button {
      // label
      display: block;
      position: relative;

      @include mobile_menu(false) {
        width: 60px;
        height: 60px;
        margin-top: -12px;
        margin-bottom: -12px;
        border-radius: 10px !important;
        margin-left: 10px;
        transition: background 0.3s;

        @include desktop() {
          width: 50px;
          height: 50px;
          margin-top: -9px;
          margin-bottom: -9px;
        }
        @include hover() {
          background-color: rgba(#fff, 0.1);
        }
      }
      @include mobile_menu() {
        opacity: 1;
        height: 56px;
      }
      img {
        // Profile icon
        position: absolute;
        width: 40px;
        top: 10px;
        left: 10px;
        border-radius: 25%;
        box-shadow: inset 0 0 0 2px rgba(#fff, 0.1);
        overflow: hidden;
        transition: transform 0.1s $easeInCubic;

        @include desktop() {
          width: 30px;
        }
        @include mobile_menu() {
          top: calc(50% - 12px);
          left: calc(50% - 12px);
          width: 24px;
          height: 24px;
        }
      }
      &:before {
        @include mobile_menu(false) {
          content: '';
          position: absolute;
          top: 10px;
          bottom: 10px;
          left: 10px;
          right: 10px;
          border-radius: 25%;
          box-shadow: inset 0 0 0 2px rgba(#fff, 0.1);
          overflow: hidden;
        }
      }
      &:active {
        @include mobile(false) {
        }
      }
    }
  }
}
body:not(.is_user) .user-navigation {
  // Not logged in

  position: fixed;
  z-index: 201;
  top: 0;
  right: 120px;
  margin-top: 21px;
  font-size: 0;
  transform: translateY(-80px);
  transition:
    transform 0.3s $easeCubic,
    box-shadow 0.3s $easeCubic;

  @include desktop() {
    right: 80px;
    margin-top: 19px;
    transform: translateY(-70px);
  }
  @include laptop() {
    right: 60px;
  }

  // For login navigation on public side; rework :)
  @include mobile_menu() {
    right: 113px;
    margin-top: 14px;
  }
  @include mobile() {
    right: 103px;
  }
  @include phone() {
    right: 83px;
  }
  &.login-navigation {
    li {
      @media (max-width: 370px) {
        display: none;
      }
      .submenu {
        width: 300px; // Login menu width

        @include mobile_menu() {
          top: calc(100% + 24px);
          left: auto;
          right: -73px;
          max-width: calc(100vw - 20px);
        }
        @include phone() {
        }
      }
      .submenu_background {
        @include mobile_menu() {
          bottom: auto;
          top: -14px;
          left: auto;
          right: -103px;
          width: 100vw;
        }
        @include mobile() {
        }
        @include phone() {
          right: -83px;
        }
      }
      .submenu_toggle:checked + * + .submenu {
        @include mobile_menu(false) {
          height: fit-content;
        }
      }
      // Styles for login and join buttons

      div > .btn {
        font-size: 16px !important;
        padding: 11px 21px !important;
        border-radius: 10px !important;
        @include button($mint);
        border-radius: 7px !important;

        @include desktop() {
          font-size: 14px !important;
          padding: 9px 18px !important;
        }
        &.has_outline {
          @include button($gray);
          background-image: none;
          box-shadow: inset 0 0 0 2px rgba($mint, 0.2);

          @include phone_little(false) {
            margin-right: 10px;
          }
          @include hover() {
            box-shadow: inset 0 0 0 2px rgba($mint, 0);
          }
        }
      }
      + li {
        // Join LogoLounge button

        @include phone_little() {
          display: none;
        }
        .btn {
          @include phone() {
            font-size: 0 !important;
          }
          &:before {
            @include phone() {
              content: 'Join';
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }
}

body:not(.is_user) .menu_toggle_button {
  @include mobile_menu() {
    @include hamburger_menu();
  }
}
body.is_user .menu_toggle_button {
  @include hamburger_menu();

  @include mobile_menu(false) {
    right: 120px - 28px;

    @include desktop() {
      right: 80px - 23px;
    }
    @include laptop() {
      right: 60px - 23px;
    }
  }
  @include mobile_menu() {
    position: absolute;
    transform: none !important;
  }
}
#menu_toggle {
  // checkbox for mobile fly-in menu
  display: none;

  &:checked {
    + .site-navigation {
      .shade {
        opacity: 1;
        pointer-events: auto;
      }
      + .menu_toggle_button {
        .a,
        .b,
        .c {
          transition:
            border 0.3s steps(4),
            transform 0.1s steps(3);
          transform: scaleX(0);
        }
        .b {
          transition-delay: 0s;
        }
        .a {
          transition-delay: 0.025s;
        }
        .c {
          transition-delay: 0.05s;
        }
        @include hover() {
          &:before {
            background-color: rgba($gray, 0.1);
          }
        }
      }
    }
  }
}
body:not(.is_user) #menu_toggle {
  &:checked + .site-navigation {
    @include mobile_menu() {
      box-shadow:
        0 0 2px rgba(#000, 0.05),
        0 0 50px rgba(#000, 0.1);
      transform: translateX(0);
    }
    @include phone() {
    }
  }
}
body.is_user #menu_toggle {
  &:checked + .site-navigation {
    box-shadow:
      0 0 2px rgba(#000, 0.05),
      0 0 50px rgba(#000, 0.1);
    transform: translateX(0);

    @include phone() {
    }
  }
}
.is_up_toggle {
  display: none;

  &:checked {
    + header.site-header {
      transform: translateY(0) !important;

      @include mobile_menu() {
      }
      + input + .site-navigation {
        + .menu_toggle_button {
          + .user-navigation {
            @include mobile_menu(false) {
              transform: translateY(0) !important;
            }
            + *,
            + * + .search_outer {
              fieldset {
                @include mobile_menu(false) {
                  transform: translateY(0) !important;
                }
              }
            }
          }
          + main,
          + * + main,
          + * + * + main,
          + * + * + * + main,
          + * + * + * + * + main {
            .search_outer {
              fieldset {
                @include mobile_menu(false) {
                  transform: translateY(0) !important;
                }
              }
            }
            .article_group_a > .article {
              @include mobile(false) {
                top: 80px + 60px;

                @include desktop() {
                  top: 70px + 60px;
                }
                @include mobile_menu() {
                  top: 60px + 60px;
                }
              }
            }
            .sticky_content {
              @include mobile(false) {
                top: 100px + 80px;

                @include desktop() {
                  top: 100px + 70px;
                }
                @include mobile_menu() {
                  top: 100px + 60px;
                }
              }
            }
            &.has_tabs {
              .tabs_outer {
                top: 80px;

                @include desktop() {
                  top: 70px;
                }
                @include mobile_menu() {
                  top: 60px;
                }
              }
              .article_group_a > .article {
                @include mobile(false) {
                  top: 80px + 45px + 80px;

                  @include desktop() {
                    top: 70px + 45px + 70px;
                  }
                  @include mobile_menu() {
                    top: 60px + 45px + 60px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
body:not(.is_user) .is_up_toggle:checked + header.site-header + input + .site-navigation {
  @include mobile_menu(false) {
    transform: translateY(0) !important;
  }
  + .menu_toggle_button {
    @include mobile_menu() {
      transform: translateY(0) !important;
    }
    + .user-navigation {
      @include mobile_menu() {
        transform: translateY(0) !important;
      }
    }
  }
}
body.is_user .is_up_toggle:checked + header.site-header + input + .site-navigation {
  + .menu_toggle_button {
    transform: translateY(0) !important;

    + .user-navigation > ul {
      @include mobile_menu() {
        transform: translate(-50%, 0) !important;
      }
    }
    + main,
    + * + main,
    + * + * + main,
    + * + * + * + main,
    + * + * + * + * + main {
      .sticky_content.register {
        @include phone() {
          bottom: 15px + 80px;
        }
      }
    }
  }
}
