// Grandfathered styles

.hidden-input {
  display: none;
}
.dropdown-label {
  display: none;
}
.is_up_toggle {
  display: none;
}

// Enable smooth scroll only after the page loads
@keyframes enableSmoothScroll {
  0% {
    scroll-behavior: auto;
  }
  100% {
    scroll-behavior: smooth;
  }
}

html {
  scroll-padding-top: 100px;

  @include mobile() {
    scroll-padding-top: 80px;
  }
  @include phone() {
    scroll-padding-top: 60px;
  }
}

body {
  background: mix(#000, $gray, 20%) linear-gradient(to bottom, $gray 49.95%, mix(#000, $gray, 20%) 50%) no-repeat 0 0/100% 100%;

  :target {
    color: $gray;
    font-family: $paragraph_font;
  }
  main {
    position: relative;
    z-index: 1;
    width: 100%;
    background: #fff;
    border-radius: 30px 30px 0 0;

    @include phone() {
      border-radius: 20px 20px 0 0;
    }
    &:not(:has(+ .footer-ribbon)) {
      border-radius: 30px;

      @include phone() {
        border-radius: 20px;
      }
    }
    @media (prefers-color-scheme: dark) {
    }
  }
  .search_outer main {

    @include mobile_menu() {
      border-radius: 0 0 30px 30px;
    }
    @include phone() {
      border-radius: 0 0 20px 20px;
    }
  }
  &.freeze {
    overflow: hidden;
  }
  &.mobile_menu_freeze {
    @include mobile_menu() {
      overflow: hidden;
    }
  }
}

.infinite-scroll-component {
  overflow: visible !important;
}
