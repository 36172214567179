.activity_feed {
  // ul
  @include activity_list();

  li {
    @include phone(false) {
      min-height: 120px;
      padding-left: 110px;
    }
    .profile_image {
      @include phone(false) {
        width: 80px;
        height: 80px;

        padding: 6px;
      }
      img {
        @include phone(false) {
          border-radius: 15px;
        }
      }
      &:after {
        @include phone(false) {
          border-radius: 15px;
        }
      }
    }
    + li:not(.has_all_button) {
      &:before {
        // line
        @include phone(false) {
          left: 110px;
        }
        @include phone() {
          left: 70px;
        }
      }
    }
  }
}
