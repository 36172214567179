body.login {
  #more_fields_toggle {
    display: none;

    + form {
      .more_fields {
        display: none;
      }
    }
    &:checked + form {
      .has_more_fields_button {
        display: none;
      }
      .more_fields {
        display: block;
      }
    }
  }
  .button_outer + fieldset {
    margin-top: 40px;
  }
  .submenu > .submenu_inner > form {
    @include form();

    fieldset:first-of-type {
      padding-top: 40px;
      border-top: solid 1px $gray_light;

      @include phone() {
      }
      legend {
        padding: 10px;
        margin: -10px;
        margin-bottom: -40px;
      }
    }
    fieldset + fieldset {
      margin-top: 40px;
    }

    .login-btn {
      margin-top: 25px;

      &.errors {
        margin-top: 15px;
      }
    }

    .container-btn {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }

    a.forgot_password,
    a.become_a_member {
      display: block;
      margin-top: 8px;
      @include p_little($gray_text);
      transition: color 0.3s;

      &:hover {
        color: $orange;
      }
      &:focus-visible {
        box-shadow: 2px 0px 0px $gray;
      }
    }
  }
}
