// Shorthand easing functions

$easeCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInCubic: cubic-bezier(0.32, 0, 0.67, 0);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutElastic: cubic-bezier(0.215, 0.61, 0.355, 1.61);

// Shortcut mixins

@mixin clear() {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
@mixin crisp() {
  image-rendering: optimizeSpeed; /* STOP SMOOTHING, GIVE ME SPEED  */
  image-rendering: -moz-crisp-edges; /* Firefox                        */
  image-rendering: -o-crisp-edges; /* Opera                          */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
  image-rendering: pixelated; /* Chrome */
  image-rendering: optimize-contrast; /* CSS3 Proposed                  */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                           */
}
@mixin hide_text() {
  overflow: hidden;
  color: transparent;
  font-size: 0;
  text-indent: -1000em;
}

@mixin hide_scrollbars() {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
@mixin disable_text_selection() {
  user-select: none;
  -webkit-user-select: none;

  * {
    user-select: none;
    -webkit-user-select: none;
  }
}
@mixin spacing($prop, $factor: 1, $less_when_greater_than_desktop: false) {
  $v: false;
  @if str-index($prop, 'top') or
    str-index($prop, 'bottom') or
    str-index($prop, '-y') or
    str-index($prop, 'height')
  {
    $v: true;
  }

  @if $less_when_greater_than_desktop == false {
    @if $v {
      #{$prop}: round(150 * $factor) * 1px;
    } @else {
      #{$prop}: round(120 * $factor) * 1px;
    }
  } @else {
    @if $v {
      #{$prop}: round(135 * $factor) * 1px;
    } @else {
      #{$prop}: round(90 * $factor) * 1px;
    }
  }

  @include desktop() {
    @if $v {
      #{$prop}: round(120 * $factor) * 1px;
    } @else {
      #{$prop}: round(80 * $factor) * 1px;
    }
  }
  @include laptop() {
    @if $v {
      #{$prop}: round(90 * $factor) * 1px;
    } @else {
      #{$prop}: round(60 * $factor) * 1px;
    }
  }
  @include mobile() {
    @if $v {
      #{$prop}: round(80 * $factor) * 1px;
    } @else {
      #{$prop}: round(50 * $factor) * 1px;
    }
  }
  @include phone() {
    @if $v {
      #{$prop}: round(80 * $factor) * 1px;
    } @else {
      #{$prop}: round(30 * $factor) * 1px;
    }
  }
}
@mixin hover() {
  &:hover {
    @include mobile(false) {
      @content;
    }
  }
  &:active {
    @include mobile() {
      @content;
    }
  }
}
@mixin inner() {
  @include spacing('padding-left');
  @include spacing('padding-right');
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  @include laptop() {
    max-width: 860px;
  }
  @include mobile() {
    max-width: 750px;
  }
}
@mixin middle_outer() {
  font-size: 0;
  overflow: hidden;
  white-space: nowrap;

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
  }
}
@mixin middle() {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}
@mixin preload() {
  transition: opacity 1s;

  &.preload {
    opacity: 0;
  }
}
@mixin lining_nums() {
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
}
@mixin cover($url: '') {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  @if $url != '' {
    background-image: image-url($url);
  }
}
@mixin user_select_none() {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

// Responsive mixins

@mixin phone_little($less: true) {
  @include respond_at_width(450px, $less) {
    @content;
  }
}
@mixin phone($less: true) {
  @include respond_at_width(600px, $less) {
    @content;
  }
}
@mixin phone_big($less: true) {
  @include respond_at_width(750px, $less) {
    @content;
  }
}
@mixin mobile($less: true) {
  @include respond_at_width(900px, $less) {
    @content;
  }
}
@mixin mobile_menu($less: true) {
  @include respond_at_width(1000px, $less) {
    @content;
  }
}
@mixin laptop($less: true) {
  @include respond_at_width(1200px, $less) {
    @content;
  }
}
@mixin desktop($less: true) {
  @include respond_at_width(1800px, $less) {
    @content;
  }
}
@mixin respond_at_width($break, $less) {
  $query: 'max-width: ' + $break;
  @if $less == false {
    $query: 'min-width: ' + ($break + 1px);
  }
  @media ( #{$query} ) {
    @content;
  }
}
.desktop_only {
  @include mobile_menu() {
    display: none !important;
  }
}
.mobile_only {
  @include mobile_menu(false) {
    display: none !important;
  }
}
.non_scrollable {
  overflow-y: hidden;
}

@function image-url($file) {
  @return url('../images/' + $file); // for local
}
