.modal_save {
  // .modal
  display: block;
  position: fixed;
  z-index: 311;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  transform: translateY(100%);
  transition:
    transform 0.5s,
    opacity 0.3s;
  visibility: hidden;
  pointer-events: none;

  .modal_inner {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    @include middle_outer();
    overflow: hidden;
    overflow-y: auto;
    white-space: normal;
    text-align: center;
  }
  .modal_inner_background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .modal_content {
    position: relative;
    width: 100%;
    max-width: 700px; // width

    box-sizing: border-box;
    text-align: left;
    background: #fff;
    border-radius: 20px;
    margin: 40px auto;
    box-shadow: 0 60px 60px -60px rgba(#000, 0.2);
    @include middle();

    @include desktop() {
      margin: 35px auto;
    }
    @include mobile_menu() {
      margin: 0 auto;
    }
    @media (max-width: 720px) {
      max-width: 100vw;
    }
    .modal-heading {
      padding: 60px 60px 0 60px;

      @include phone() {
        padding-top: 40px;
        padding-left: 30px;
        padding-right: 30px;
      }
      h3 {
        @include h_c($foam);
      }
    }
    .modal-body {
      position: relative;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;

      @media (max-height: 790px) {
        height: calc(100vh - 268px);

        @include phone() {
          height: calc(100vh - 209px + 60px - 78px);
        }
      }
      form {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        box-sizing: border-box;
        white-space: normal;
        padding: 60px;
        transition: transform 0.3s $easeCubic;

        @include phone() {
          padding-top: 40px;
          padding-bottom: 40px;
          padding-left: 30px;
          padding-right: 30px;
        }
      }
      [data-action-value='add-to-collection'] {
        // Collections list
        height: 520px;
        overflow: hidden;
        overflow-y: auto;

        @media (max-height: 790px) {
          height: 100%;
        }
      }
      #new-collection-form {
        // form
        @include form();
        height: 100%;
        overflow: hidden;
        overflow-y: auto;

        .form-group:first-child label {
          margin-top: 0;
        }
      }
      &:before {
        // top and bottom white gradient to allude to scrollability
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom,
          rgba(#fff, 1) 0px,
          rgba(#fff, 1) 20px,
          rgba(#fff, 0) 60px,
          rgba(#fff, 0) calc(100% - 60px),
          rgba(#fff, 1) calc(100% - 20px),
          rgba(#fff, 1) 100%
        );
        pointer-events: none;

        @include phone() {
          background: linear-gradient(
            to bottom,
            rgba(#fff, 1) 0px,
            rgba(#fff, 1) 20px,
            rgba(#fff, 0) 40px,
            rgba(#fff, 0) calc(100% - 40px),
            rgba(#fff, 1) calc(100% - 20px),
            rgba(#fff, 1) 100%
          );
        }
      }
    }
    .modal-actions {
      text-align: right;
      padding: 0 60px 60px 60px;

      @include phone() {
        padding-bottom: 40px;
        padding-left: 30px;
        padding-right: 30px;
      }
      .done_button {
        float: left;
        @include button($mint);
        &:disabled {
          background: #cacaca !important;
          &:hover {
            color: #474747;
          }
        }
      }
      .new_collection_button {
        @include button(#f2f2f2);
      }
      .save_new_collection_button {
        @include button($orange);
      }
      .cancel_button,
      .save_new_collection_button {
        display: none;
      }
    }
    #new_collection_toggle:checked + .modal-body {
      form {
        transform: translateX(-100%);
      }
      + .modal-actions {
        .done_button {
          display: none;
        }
        .new_collection_button {
          float: left;
          font-size: 0;

          &:before {
            content: 'Cancel';
            font-size: 18px;

            @include laptop() {
              font-size: 16px;
            }
          }
        }
        .cancel_button,
        .save_new_collection_button {
          display: inline-block;
        }
      }
    }
  }
  .collections-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @include phone() {
      gap: 10px;
    }

    .collection-box {
      display: contents;
    }
    .collection-item {
      // article
      position: relative;
      width: calc(33.333% - (20px * 2 / 3));
      box-shadow: inset 0 0 0 2px $gray_light;
      border-radius: 15px;
      transition: box-shadow 0.3s;

      @include phone() {
        width: calc(50% - (10px * 1 / 2));
      }
      &-logos {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin: 20px 20px 17px 20px;

        .logo_item {
          margin: 0;
          width: calc(33.333% - (10px * 2 / 3));

          .image_outer {
            position: relative;
            width: 100%;
            height: 0;
            padding-top: 100%;
            border-radius: 25%;
            background: $gray_light;
            overflow: hidden;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
          }
        }
      }
      &-info {
        box-sizing: border-box;
        padding: 0 20px 20px 20px;

        h3 {
          @include h_e();
          margin-bottom: 2px;
        }
        .collection-item-meta {
          @include p_little($gray_mid);
        }
      }
      label {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        @include disable_text_selection();

        span {
          display: none; // :)
        }
      }
      &:after {
        // x button
        content: '';
        position: absolute;
        top: 10px;
        right: 10px;
        width: 23px;
        height: 23px;
        background: image-url('../../assets/images/icon/x_gray_little.png') no-repeat 50%
          50%/9px auto;
        border-radius: 100%;
        opacity: 0;
        transition:
          opacity 0.3s,
          transform 0.3s $easeCubic;
        pointer-events: none;
      }

      @include hover() {
        box-shadow: inset 0 0 0 2px mix($gray, $gray_light, 10%);
      }
    }
    input[type='checkbox']:checked + .collection-item {
      box-shadow: inset 0 0 0 4px $foam;

      &:after {
        // x icon
        opacity: 0.3;
      }
      @include hover() {
        &:after {
          // x icon
          opacity: 1;
        }
      }
    }
  }
  &.visible {
    transform: translateY(0);
    visibility: visible;
    pointer-events: auto;

    + .modal_save_shade {
      opacity: 1;
    }
  }
  &.delay {
    visibility: visible;
  }
  &.overlapping {
    z-index: 400;
  }
}
.modal_save_shade {
  position: fixed;
  z-index: 310;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  background: rgba(#000, 0.2);
  transition: opacity 0.5s;
}
