@use 'sass:math';
@mixin logo() {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  background: #fff;
  box-shadow: inset 0 0 0 2px rgba(#000, 0.1);
  padding: 7.5% 7.5% 92.5% 7.5%;
  overflow: hidden;
  transition:
    box-shadow 0.3s,
    transform 0.3s $easeOutElastic;

  .image {
    position: absolute;
    z-index: 1;
    top: 7.5%;
    left: 7.5%;
    display: block;
    width: 85%;
    height: 85%;
    object-fit: contain;
    visibility: hidden; // hidden until loaded
  }
  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    display: block;
    top: 7.5%;
    bottom: 7.5%;
    left: 7.5%;
    right: 7.5%;
    @include shimmer();
  }
  &.has_award {
    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      right: -4%;
      top: -4%;
      width: 30%;
      height: 30%;
      border-radius: 100%;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 75% auto;
    }
    @for $i from 1 through $max_books {
      &.book-#{$i}:after {
        background-image: image-url('../../assets/images/icon/award/book_#{$i}.svg');
      }
    }
    @for $i from 1 through $max_master_books {
      &.master-book-#{$i}:after {
        background-image: image-url(
          '../../assets/images/icon/award/master_book_#{$i}.svg'
        );
      }
    }
    .master_award {
      &:after {
        content: '';
        position: absolute;
        z-index: 2;
        right: -4%;
        top: -4%;
        width: 30%;
        height: 30%;
        border-radius: 100%;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 75% auto;
      }
      &.second_award::after {
        right: 24%;
      }
      @for $i from 1 through $max_master_books {
        &.master-book-#{$i}:after {
          background-image: image-url(
            '../../assets/images/icon/award/master_book_#{$i}.svg'
          );
        }
      }
      &.hidden {
        display: none;
      }
    }
  }
  &.loaded {
    .image {
      visibility: visible;
    }
    &:before {
      content: none; // hide loading animation
    }
  }
  @include hover() {
    box-shadow: inset 0 0 0 2px rgba(#000, 0.2);
  }
}
@mixin logo_list_item($size: 5) {
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  box-sizing: border-box;
  transition: transform 0.3s $easeOutElastic;

  @if $size == 5 {
    width: percentage(math.div(1, 5));
  }
  @if $size == 2 {
    width: percentage(math.div(1, 2));
  }

  @include phone_big() {
    padding: 8px;

    @if $size == 5 {
      width: percentage(math.div(1, 4));
    }
  }
  @include phone() {
    @if $size == 5 {
      width: percentage(math.div(1, 3));
    }
  }
  @include phone_little() {
    width: percentage(math.div(1, 2));
  }
  .logo_outer {
    position: relative;
    border-radius: 15px;

    .action_outer_container {
      position: relative;
    }

    .action_outer {
      position: absolute;
      z-index: 2;
      bottom: -20px;
      width: 61px;
      height: 61px;

      a,
      label {
        display: block;
        position: absolute;
        top: 10px;
        left: 10px;
        width: 41px;
        height: 41px;
        font-size: 0;
        border-radius: 100%;
        transform: scale(0);
        box-sizing: border-box;
        background-color: #fff;
        box-shadow:
          0 0 2px rgba(#000, 0.05),
          0 10px 20px rgba(#000, 0.05);
        transition:
          background 0.3s,
          transform 0.3s $easeInCubic,
          box-shadow 0.3s $easeCubic;
        cursor: pointer;

        &:before {
          content: '';
          position: absolute;
          top: calc(50% - 7.5px);
          left: calc(50% - 7.5px);
          width: 15px;
          height: 15px;
          background-size: 100% auto;
          background-position: 0 0;
          background-repeat: no-repeat;
          opacity: 0.2;
          transition:
            background 0.3s,
            opacity 0.3s;
        }
        &.like_button:before {
          background-image: image-url('../../assets/images/icon/like_little_gray.png');
        }
        &.save_button {
          &:before {
            background-image: image-url('../../assets/images/icon/save_little_gray.png');
          }
        }
        &.edit_button {
          &:before {
            background-image: image-url('../../assets/images/icon/edit_little_gray.png');
          }
        }
        &.remove_button {
          &:before {
            background-image: image-url('../../assets/images/icon/x_gray.png');
          }
        }
        @include hover() {
          background-color: $gray_light;
        }
        &:active {
          @include mobile(false) {
            transform: scale(0.8) !important;
          }
        }
      }
      &.like_button_outer {
        left: -20px;
      }
      &.save_button_outer {
        left: 31px;
      }
      &.remove_button_outer {
        left: 80px;
      }
      &.edit_button_outer {
        left: 82px;
      }
      &.like_container {
        bottom: -20px;
      }
    }
    &:hover {
      .action_outer {
        a,
        label {
          transform: scale(1);
          transition:
            background 0.3s,
            transform 0.3s $easeOutElastic,
            box-shadow 0.3s $easeCubic; // Elastic only on hover, not on un-hover

          &.save_button {
            transition:
              background 0.3s,
              transform 0.3s $easeOutElastic 0.1s,
              box-shadow 0.3s $easeCubic;
          }
          &.edit_button {
            transition:
              background 0.3s,
              transform 0.3s $easeOutElastic 0.2s,
              box-shadow 0.3s $easeCubic;
          }
        }
      }
    }
  }
  .logo {
    // a
    @include logo();
  }
  .like_button_toggle {
    display: none;

    &:checked + * + .logo_outer {
      .like_button {
        background: #fff;
        transform: none;

        &:before {
          opacity: 1;
          background-image: image-url('../../assets/images/icon/like_little_orange.png');
        }
        @include hover() {
          background-color: $gray_light;
        }
      }
    }
  }
  .save_button_toggle {
    display: none;

    &:checked + .logo_outer {
      .save_button {
        background: #fff;
        transform: none;
        padding: 0 !important;

        &:before {
          opacity: 1;
        }
        @include hover() {
          background-color: $gray_light;
        }
      }
    }
  }
  .designer {
    position: relative;
    display: flex;
    @include p_little();
    margin-top: 15px;
    margin-bottom: 10px;

    .image {
      display: inline-block;
      vertical-align: middle;
      width: 21px;
      height: 21px;
      border-radius: 25%;
      background: $gray_light;
      margin-right: 10px;
      margin-bottom: 0 !important;
    }
    .name {
      display: inline-block;
      vertical-align: middle;

      @include hover() {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  }
  .logo_cert_info {
    margin: 20px 5px;
    @include p_little();

    strong {
      font-weight: 600;
    }
    div {
      + div {
        margin-top: 10px;
      }
    }
  }
}
.logos_outer {
  ul {
    position: relative;
    margin: -10px;
    @include clear();

    @include phone_big() {
      margin: -8px;
    }
    li {
      @include logo_list_item();

      &.promo {
        margin-bottom: -1px;

        a {
          position: relative;
          display: block;
          background: #fff;
          border-radius: 15px;
          width: 100%;
          height: 0;
          padding-top: 100%;
          overflow: hidden;
          box-shadow: inset 0 0 0 2px $gray_light;
          transition: box-shadow 0.3s;

          .image {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: image-url('../../assets/images/banner_book_13_bg.png') no-repeat
              100% 66.667%/800% auto;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: 50% 50%;
            }
          }
          .text {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            padding: 40px;
            box-sizing: border-box;

            @include mobile() {
              padding: 30px;
            }
            .title {
              display: block;
              @include h_c();
              padding-right: 40px;

              &:first-child {
                margin-top: -6px !important; // optical spacing
              }
            }
            .date {
              display: block;
              position: absolute;
              top: 40px;
              right: 40px;
              @include p_little($gray_mid);
              line-height: 1em !important;

              @include mobile() {
                top: 30px;
                right: 30px;
              }
            }
            .excerpt {
              display: block;
              max-height: 3em;
              overflow: hidden;
              @include p($gray_mid);
              margin-top: 7px;

              @include phone() {
                font-size: 14px;
              }
            }
          }
          @include hover() {
            box-shadow: inset 0 0 0 2px rgba(#000, 0.2);
          }
        }
        &.left {
          float: left;
        }
        &.right {
          float: right;
        }
        &.size_2x1,
        &.size_2x2 {
          width: 40%;

          @include phone_big() {
            width: 50%;
          }
          @include phone() {
            width: percentage(math.div(2, 3));
          }
          @include phone_little() {
            width: 100%;
          }
        }
        &.size_2x1 {
          a {
            padding-top: calc(50% - 10px);
          }
          .image {
            background: image-url('../../assets/images/banner_book_13_bg.png') no-repeat
              100% 50%/400% auto;
          }
        }
        &.size_2x2 {
          .image {
            height: 50%;
            background: image-url('../../assets/images/banner_book_13_bg.png') no-repeat
              100% 50%/400% auto;
          }
        }
        &.size_1x2 a {
          padding-top: calc(200% + 20px);

          @include phone_big() {
            padding-top: calc(200% + 10px);
          }
          .image {
            background: image-url('../../assets/images/banner_book_13_bg.png') no-repeat
              0.33% 50% / auto 201%;
          }
        }
        &.size_1x3 {
          a {
            padding-top: calc(300% + 40px);

            @include phone_big() {
              padding-top: calc(300% + 20px);
            }
          }
        }
      }
    }
  }
  &:not(:last-child) {
    margin-bottom: 60px;
  }
}

.page_button_outer {
  position: relative;
  width: calc(50% - 10px);
  margin-top: 20px;

  @include phone_big() {
    width: calc(50% - 8px);
    margin-top: 16px;
  }
  @include phone() {
  }
  @include phone_little() {
  }
  .prev_button {
    @include button_big(#fff);
    @include h_b();
    justify-content: center;
    color: $gray;
    background: mix(#fff, $gray_light, 33%);

    &:hover {
      color: $gray;
      background: $mint;
    }
  }
  .next_button {
    @include button_big($mint);
    @include h_b();
    justify-content: center;
  }
  .prev_button.button,
  .next_button.button {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 15px !important;
    box-sizing: border-box;
    align-items: center;
    text-align: center;

    @include phone_big() {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  &.prev_button_outer {
    float: left;
  }
  &.next_button_outer {
    float: right;
  }
  + .page_button_outer {
    margin-left: 20px;

    @include phone_big() {
      margin-left: 16px;
    }
  }
}
.results_count_top {
  @include p_little($gray_mid);
  padding-top: 30px;
  text-align: center;
  clear: both;

  @include phone() {
    padding-top: 20px;
  }
  + section .content_outer {
    padding-top: 30px !important;

    @include phone() {
      padding-top: 20px !important;
    }
  }
}
.results_count {
  @include p_little($gray_mid);
  padding-bottom: 20px;
  padding-top: 10px;
  text-align: center;
  clear: both;
}