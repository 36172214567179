.collection {
  main {
    .content_outer {
      &:not(.has_sticky_left):not(.has_sticky_right) h1 {
        @include h_b($foam);

        @include mobile(false) {
          margin-bottom: 10px !important;
        }
      }
    }
  }
  .content_outer {
    padding-top: 0 !important;
  }
  .page_header_outer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .page_header {
    padding-left: 0 !important;
  }

  .gray_button {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    color: #fff;
    font-family: 'Inter', Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 1;
    text-decoration: none !important;
    padding: 13px 30px 13px 30px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #f2f2f2;
    -webkit-transition:
      color 0.3s,
      background 0.3s,
      -webkit-box-shadow 0.3s;
    transition:
      color 0.3s,
      background 0.3s,
      -webkit-box-shadow 0.3s;
    transition:
      color 0.3s,
      background 0.3s,
      box-shadow 0.3s;
    transition:
      color 0.3s,
      background 0.3s,
      box-shadow 0.3s,
      -webkit-box-shadow 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #474747;
    margin-right: auto;
  }
  .gray_button:hover {
    background-color: #ddeecd;
  }

  .save_btn {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    color: #fff;
    font-family: 'Inter', Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 20px !important;
    line-height: 1;
    text-decoration: none !important;
    padding: 13px 30px 13px 30px !important;
    border-radius: 10px;
    overflow: hidden;
    background-color: #ed6213;
    -webkit-transition:
      color 0.3s,
      background 0.3s,
      -webkit-box-shadow 0.3s;
    transition:
      color 0.3s,
      background 0.3s,
      -webkit-box-shadow 0.3s;
    transition:
      color 0.3s,
      background 0.3s,
      box-shadow 0.3s;
    transition:
      color 0.3s,
      background 0.3s,
      box-shadow 0.3s,
      -webkit-box-shadow 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #ed6213 url('../../assets/images/layout/orange_button_bg.png') no-repeat
      50% 50%/101% 100%;
    width: auto;
  }
  .save_btn:hover {
    background-color: #ed6213;
  }
}
