// Heading styles

@mixin h($color) {
  color: $color;

  span {
    display: inline-block;
  }
  &:first-child {
    margin-top: 0 !important;
  }
  &:last-child {
    // margin-bottom: 0 !important;
  }
}
@mixin h_a($color: $gray) {
  @include h($color);
  font-family: $heading_font;
  font-size: 78px;
  font-weight: 700;
  line-height: 1;

  @include desktop() {
    font-size: 68px;
  }
  @include mobile() {
    font-size: 58px;
  }
  @include phone() {
    font-size: 52px;
  }
	@include phone_little() {
		font-size: 42px;
	}
}
@mixin h_b($color: $gray) {
  @include h($color);
  font-family: $heading_font;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;

  @include desktop() {
    font-size: 44px;
  }
  @include mobile() {
    font-size: 38px;
  }
  @include phone() {
    font-size: 32px;
  }
}
@mixin h_c($color: $gray) {
  @include h($color);
  font-family: $heading_font;
  font-size: 30px;
  line-height: 1.2;
  font-weight: 700;

  @include desktop() {
    font-size: 28px;
  }
  @include mobile() {
    font-size: 26px;
  }
  @include phone() {
    font-size: 24px;
  }
}
@mixin h_d($color: $gray) {
  @include p_bigger($color);
  font-weight: 600;
  line-height: 1.3;
}
@mixin h_e($color: $gray) {
  @include p($color);
  font-weight: 600;
  line-height: 1.4;
}
@mixin label($color: $foam) {
  @include h($color);
  font-family: $paragraph_font;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 13px;
  line-height: 1.4;

  @include mobile() {
    font-size: 12px;
  }
}

// Paragraph styles

@mixin p_little($color: $gray) {
  @include p($color);
  font-size: 14px;
  letter-spacing: 0.02em;

	@include mobile() {
		font-size: 13px;
	}
	@include phone() {
		font-size: 12px;
	}
}
@mixin p($color: $gray) {
  color: $color;
  font-family: $paragraph_font;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.5;
	
	@include phone() {
		font-size: 15px;
	}
}
@mixin p_article($color: $gray) {
  @include p($color);
  font-size: 18px;

  @include mobile() {
    font-size: 16px;
  }
}
@mixin p_bigger($color: $gray) {
  @include p($color);
  font-family: $heading_font;
  font-size: 20px;
  letter-spacing: 0;
  font-weight: 400;

  @include mobile() {
		font-size: 18px;
  }
  @include phone() {
    font-size: 17px;
  }
}
@mixin p_big($color: $gray) {
  @include p($color);
  font-family: $heading_font;
  font-size: 27px;

  @include desktop() {
    font-size: 24px;
  }
  @include mobile() {
    font-size: 20px;
  }
}

// Other styles

@mixin button($color: $orange) {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  color: #fff;
  font-family: $paragraph_font;
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  text-decoration: none !important;
  padding: 13px 30px 13px 30px;
  border-radius: 10px;
  overflow: hidden;
  background-color: $color;
  transition:
    color 0.3s,
    background 0.3s,
    box-shadow 0.3s;
  @include user_select_none();

  @if ($color == #f2f2f2 or $color == #eeeeee or $color == $gray_light) {
    color: $gray;
  }
  @if ($color == $mint) {
    color: $gray;
    background: $mint
      image-url('../../assets/images/layout/mint_button_bg.png')
      no-repeat
      50%
      50%/101%
      100%;
  }
  @if ($color == $orange) {
    background: $orange
      image-url('../../assets/images/layout/orange_button_bg.png')
      no-repeat
      50%
      50%/101%
      100%;
  }

  @include desktop() {
    font-size: 18px;
    padding: 12px 28px 12px 28px;
  }
  @include laptop() {
    font-size: 16px;
  }
  @include mobile() {
    padding: 11px 25px 11px 25px;
  }
  @include hover() {
    @if ($color != $gray_light and $color != #eeeeee and $color != #f2f2f2) {
      color: #fff;
    } @else {
      color: $gray;
    }
    background-image: image-url('../../assets/images/layout/transparent.gif');

    @if ($color == #f2f2f2 or $color == #eeeeee) {
      background-color: $mint;
    }
    @if ($color == $gray_light) {
      background-color: mix($gray, $gray_light, 20%);
    }
    @if ($color == $gray) {
      background-color: mix($gray, $mint, 80%);
    }
    @if ($color == $mint) {
      background-image: image-url('../../assets/images/layout/orange_button_bg.png');
    }

    &:before {
      @include mobile(false) {
      }
    }
  }
  &:focus-visible {
    box-shadow: inset 0 0 0 2px $foam;

    @if ($color == $mint) {
      box-shadow: inset 0 0 0 2px #fff;
    }
  }
}
@mixin button_big($color: $orange) {
  @include button($color);
  font-size: 22px;
  padding: 16px 35px 16px 35px;

  @include desktop() {
    font-size: 22px;
    padding: 15px 30px;
  }
  @include laptop() {
    font-size: 18px;
    padding: 15px 30px;
  }
  @include mobile() {
    font-size: 18px;
    padding: 15px 30px;
  }
}
@mixin button_white() {
  @include button(#fff);
  color: $gray;
  box-shadow: inset 0 0 0 2px $gray_light;

  &:hover {
    color: $gray;
    background: mix(#fff, $gray_light, 33%);
    box-shadow: inset 0 0 0 2px transparent;
  }
}
@mixin button_little($color: $orange) {
  @include button($color);
  font-weight: 600;
  font-size: 16px !important;
}
@mixin button_tiny() {
  display: inline-block;
  @include p_little();
  font-weight: 600;
  padding: 10px 18px 10px 39px;
  margin: 0 -18px 0 -20px;
  border-radius: 10px;
  background: #ffff image-url('../../assets/images/layout/arrow_tiny_gray.svg') no-repeat
    20px 50%/8px auto;
  transition:
    color 0.3s,
    background 0.3s;
  @include hover() {
    background-color: $mint;
  }
}
@mixin arrow_button($color: $orange) {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  color: $gray;
  font-family: $heading_font;
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  padding: 20px 30px 20px 30px;
  padding-left: 64px + 15px;
  transition: color 0.3s;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 32px);
    width: 64px;
    height: 64px;
    border-radius: 100%;
    transition: background 0.3s;
    background-repeat: no-repeat, no-repeat;
    background-size:
      30px auto,
      101% 100%;
    background-position:
      50% 50%,
      50% 50%;
    background-color: $color;

    @if ($color == $mint) {
      background-image: image-url('../../assets/images/layout/arrow_right.png'),
        image-url('../../assets/images/layout/mint_button_bg.png');
    }
  }

  @include desktop() {
    font-size: 18px;
  }
  @include laptop() {
    font-size: 16px;
  }
  @include mobile() {
  }
  &:hover {
    &:before {
      @if ($color == $mint) {
        background-image: image-url('../../assets/images/layout/arrow_right.png'),
          image-url('../../assets/images/layout/orange_button_bg.png');
      }
    }
  }
}
@mixin save_button() {
}

@mixin inner_width() {
  width: auto;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  @include spacing('padding-left');
  @include spacing('padding-right');
  box-sizing: content-box;

  @include desktop(false) {
  }
  @include phone_big() {
  }
  @include phone() {
  }
}
@mixin inner_width_wide() {
  @include inner_width();
  max-width: 600px + 433px;

  @include desktop(false) {
    // Wider than the desktop breakpoint
  }
}

@mixin cover_trends() {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;

  box-sizing: border-box;

  transition: transform 1s $easeCubic;

  .logo {
    position: relative;
    display: inline-block;
    flex-basis: 33.333%;
    height: 50%;
    @include hide_text();
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 200% auto;
    animation: trend_variants 0.5s step-end infinite forwards;
    animation-play-state: paused;

    &:hover {
      animation-play-state: running;
    }
    &:after {
      // cover-up
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 12%;
      background: #fff;
    }
  }
}
@keyframes trend_variants {
  0% {
    background-position: 0 1.4vw;
  }
  25% {
    background-position: 100% 1.4vw;
  }
  50% {
    background-position: 100% calc(100% + 1.4vw);
  }
  75% {
    background-position: 0 calc(100% + 1.4vw);
  }
  100% {
    background-position: 0 1.4vw;
  }
}
@keyframes trend_variants_2003 {
  0% {
    background-position: 0 1.4vw;
  }
  33.333% {
    background-position: 100% 1.4vw;
  }
  66.667% {
    background-position: 50% calc(100% + 1.4vw);
  }
  100% {
    background-position: 0 1.4vw;
  }
}
@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  40% {
    background-position: -200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@mixin caption_heading($has_buttons: false) {
  position: relative;
  display: flex;
  align-items: center;

  @include p($gray_text);

  @include phone() {
    @if $has_buttons == true {
    }
  }
  .profile_image {
    // a

    width: 50px;
    height: 50px;
    margin-right: 30px;
    box-shadow: inset 0 0 0 2px $gray_light;
    border-radius: 25%;
    transition: box-shadow 0.3s;
    overflow: hidden;

    @include desktop() {
      top: 6px;
    }
    @include phone() {
      top: 3px;
      margin-right: 20px;
    }
    &.skeleton {
      box-shadow: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
    @include hover() {
      box-shadow: inset 0 0 0 2px mix($gray, #fff, 30%);
    }
  }
  .text {
    width: calc(100% - 80px);
  }
  .title {
    // a
    display: block;
    @include h_e();
    margin-bottom: 2px;

    &[href] {
      @include hover() {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
    .skeleton {
      width: 120px;
      height: 16px;
      margin-bottom: 8px;
      border-radius: 100px;
      // background: #f2f2f2;
      background: linear-gradient(to right, #eee 25%, #f5f5f5 50%, #eee 75%);
      background-size: 200% 100%;
      animation: skeleton-loading 1.5s infinite linear;
    }
  }
  .author,
  .infos {
    display: block;
    @include p_little($gray_text);

    a {
      font-weight: 600;
      transition: color 0.3s;

      @include hover() {
        color: $black;
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
    .follow_button {
      color: $foam;
      cursor: pointer;

      @include hover() {
      }
    }
    .skeleton {
      width: 60px;
      height: 10px;
      border-radius: 100px;
      // background: #f2f2f2;
      background: linear-gradient(to right, #eee 25%, #f5f5f5 50%, #eee 75%);
      background-size: 200% 100%;
      animation: skeleton-loading 1.5s infinite linear;
    }
  }
  .logo_count {
    display: none; // :)
    @include p_little(inherit);
    transition:
      color 0.3s,
      opacity 0.3s;
    cursor: pointer;

    @include mobile(false) {
      float: right;
      margin-top: -22px;
      opacity: 0;
    }
    @include mobile() {
      display: none;
      margin-top: 5px;
    }
  }
}

@mixin shimmer() {
  background: linear-gradient(90deg, #fff 0%, #fff 25%, #f2f2f2 50%, #fff 75%, #fff 100%)
    no-repeat 0 0/200% auto;
  animation: shimmer 3s linear infinite forwards;
}
