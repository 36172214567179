.parent-container {
  position: relative;
  width: 100%;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.svg-container {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/images/layout/logolounge_logo_mono_white_33.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  overflow: hidden;
}

.svg-container::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 2000px 100%;
  animation: shimmer 4s infinite linear;
  border-radius: 10px;
}

@keyframes shimmer {
  0% {
    background-position: -2000px 0;
  }
  100% {
    background-position: 2000px 0;
  }
}
