@mixin activity_list() {
  li {
    display: block;
    position: relative;
    @include p($gray_mid);
    padding: 20px 0 20px 70px;
    width: auto !important;
    min-height: 90px;
    box-sizing: border-box;

    .time {
      float: right;
      @include p_little($gray_mid);
      margin: 0 0 15px 15px;

      &.skeleton {
        display: block;
        width: 50px;
        height: 16px;
        margin-bottom: 8px;
        border-radius: 100px;
        // background: #f2f2f2;
        background: linear-gradient(to right, #eee 25%, #f5f5f5 50%, #eee 75%);
        background-size: 200% 100%;
        animation: skeleton-loading 1.5s infinite linear;
      }
    }
    .profile_image {
      position: absolute;
      top: 20px;
      left: 0;
      width: 50px;
      height: 50px;
      padding: 4px;
      box-sizing: border-box;
      border-radius: 25%;

      img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 25%;
        overflow: hidden;
      }
      &.is_save,
      &.is_like,
      &.is_comment,
      &.is_follow {
        &:before {
          content: '';
          position: absolute;
          z-index: 2;
          bottom: -9px;
          left: -9px;
          width: 31px;
          height: 31px;
          background-color: $mint;
          background-size: 15px auto;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          border-radius: 100%;
        }
      }
      &.is_save:before {
        background-image: image-url('../../assets/images/icon/save_little_gray.png');
      }
      &.is_like:before {
        background-image: image-url('../../assets/images/icon/like_little_gray.png');
      }
      &.is_comment:before {
        background-image: image-url('../../assets/images/icon/comment_gray.png');
      }
      &.is_follow {
        padding: 0;

        &:before {
          background-image: image-url('../../assets/images/icon/follow_little_gray.png');
        }
        &:after {
          content: none;
        }
      }
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 25%;
        box-shadow: inset 0 0 0 2px rgba(#000, 0.1);
        transition: box-shadow 0.3s;
      }
      &.skeleton {

        &:after {
          box-shadow: none;
        }
      }
      @include hover() {
        &:after {
          box-shadow: inset 0 0 0 2px mix($gray, #fff, 30%);
        }
      }
    }
    a {
      font-weight: 500;
      color: $black;
      transition: color 0.3s;

      @include hover() {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
      &.designer_name {
        img {
          display: none !important; // :)
          vertical-align: middle;
          width: 30px !important;
          border-radius: 25%;
          margin-right: 7px;
        }
        &.skeleton {
          display: block;
          width: 150px;
          height: 16px;
          margin-bottom: 8px;
          border-radius: 100px;
          // background: #f2f2f2;
          background: linear-gradient(to right, #eee 25%, #f5f5f5 50%, #eee 75%);
          background-size: 200% 100%;
          animation: skeleton-loading 1.5s infinite linear;
        }
      }
    }
    &.has_all_button {
      display: block;
      min-height: 0;
      text-align: right;
      padding: 0;
    }
    + li:not(.has_all_button) {
      position: relative;

      &:before {
        // line
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50px;
        right: 0;
        border-top: solid 1px $gray_light;
      }
    }
    &.is_message {
      margin-top: 20px;
      padding: 0;

      a {
        display: block;
        padding: 20px 30px;
        background: $mint image-url('../../assets/images/banner_mint_bg_big.png')
          no-repeat 100% 50% / auto 330%;
        text-decoration: none !important;

        .heading {
          display: block;
          @include p_article();
          font-weight: 700;
          margin-bottom: 1px;
          margin-top: -2px;
        }
        .paragraph {
          display: block;
          @include p_little();
          font-weight: 600;
          padding-left: 18px;
          background: image-url('../../assets/images/layout/arrow_tiny_gray.svg')
            no-repeat 0 50%/8px auto;
        }
      }
      + li:before {
        content: none;
      }
    }
  }
}
.user-navigation li.has_activity_menu {
  .submenu {
    @include mobile_menu(false) {
    }
  }
  .submenu_inner {
    @include phone() {
    }
  }
  ul {
    @include activity_list();
    margin-top: -20px;

    li {
      @include p_little($gray_mid);
      padding-left: 50px;
      min-height: 0;

      .profile_image {
        top: calc(50% - 15px);
        width: 31px;
        height: 31px;

        img {
          display: none;
        }
        &:before {
          left: 0 !important;
          top: 0;
          bottom: auto;
        }
        &:after {
          content: none;
        }
      }
    }
  }
  .submenu_toggle:checked + * + .submenu {
    @include mobile_menu(false) {
      height: auto;
      max-height: calc(100vh - 71px);
      overflow-y: auto;

      @include desktop() {
        max-height: calc(100vh - 61px);
      }
    }
    @include mobile_menu() {
      max-height: calc(100vh - 76px - 10px - 58px);
      overflow-y: auto;
    }
  }
}
