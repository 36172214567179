@mixin logo_detail() {
  .logo_full {
    position: relative;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;

    @include phone() {
      padding: 30px;
    }
    .image {
      position: relative;
      z-index: 1;
      width: 500px;
      max-width: 100%;
      height: 500px;
      margin: 0 auto !important;
      object-fit: contain;

      @media (max-width: 560px) {
        height: calc(100vw - 60px);
      }
    }
    .actions {
      position: absolute;
      z-index: 1;
      @include spacing('top', 0.5);
      @include spacing('right', 1, true);
      width: 120px;
      font-size: 0;

      @include mobile() {
        width: auto;
        top: 100%;
        white-space: nowrap;
      }
      @include phone() {
        display: flex;
        position: relative;
        width: 100%;
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 30px;
      }
      .skeleton-wrapper {
        display: contents;
      }
      .skeleton,
      label,
      a {
        position: relative;
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        @include p($gray);
        font-weight: 600;
        line-height: 45px;
        text-align: center;
        border-radius: 10px;
        box-sizing: border-box;
        background-color: #f2f2f2;
        padding-left: 34px !important;
        padding-right: 0 !important;
        transition:
          color 0.3s,
          background 0.3s;
        cursor: pointer;
        @include disable_text_selection();

        @include mobile() {
          @include phone(false) {
            width: 55px;
            height: 50px;
            font-size: 0;
          }
        }
        @include phone() {
          width: calc(50% - 5px);
        }
        &:before {
          content: '';
          display: inline-block;
          width: 15px;
          height: 15px;
          position: absolute;
          top: calc(50% - 7px);
          left: 20px;
          background-size: 100% auto;
          background-position: 0 0;
          background-repeat: no-repeat;
          opacity: 0.2;
          transition:
            background 0.3s,
            opacity 0.3s;
        }
        + .skeleton,
        + label,
        + a {
          margin-top: 15px;

          @include mobile() {
            margin-top: 0;
            margin-left: 10px;
          }
        }
        &.like_button {
          background-size: 101% 101%;

          &:before {
            background-image: image-url('../../assets/images/icon/like_little_gray.png');
          }
        }
        &.save_button {
          color: $gray;

          &:before {
            background-image: image-url('../../assets/images/icon/save_little_gray.png');
          }
        }
        &.edit_button {
          color: $gray;

          &:before {
            background-image: image-url('../../assets/images/icon/edit_little_gray.png');
          }
        }
        @include hover() {
          background-color: $gray_light;
          color: $gray;
        }
      }
      .skeleton {

        &:after { 
          content: '_';
          color: transparent !important;
        }
      }
      input[type='checkbox'] {
        display: none;

        &.like_button_toggle:checked + * + label {
          background-image: none;
          background-color: #fff;
          box-shadow: inset 0 0 0 2px $gray_light;
          color: $gray;

          &:before {
            opacity: 1;
            background-image: image-url(
              '../../assets/images/icon/like_little_orange.png'
            );
          }
          &:after {
            content: 'd';
          }
        }
        &.save_button_toggle:checked + * + label {
          background-image: none;
          background-color: #fff;
          box-shadow: inset 0 0 0 2px $gray_light;
          color: $gray;

          &:before {
            opacity: 1;
          }
          &:after {
            content: 'd';
          }
        }
      }
    }
    &.loaded {
      &:before {
        content: none;
      }
    }
  }
  .text_left {
    @include spacing('padding-left', 1, true);
    @include spacing('padding-right', 1, true);
    box-sizing: border-box;

    @include mobile(false) {
      display: inline-block;
      vertical-align: top;
      width: 60%;
      border-right: solid 1px $gray_light;
    }
  }
  .text_right {
    @include spacing('padding-left', 1, true);
    @include spacing('padding-right', 1, true);
    box-sizing: border-box;

    @include mobile() {
      margin-top: 40px;
    }
    @include phone() {
      margin-top: 30px;
    }

    @include mobile(false) {
      display: inline-block;
      vertical-align: top;
      width: 40%;
    }
    @include mobile() {
      border-top: solid 1px $gray_light;
      padding-top: 40px;
      margin-top: 55px;
    }
  }
  .collection_caption {
    @include caption_heading();
  }
  .logo_caption {
    @include caption_heading(true);

    @include mobile() {
      padding-right: 160px;
    }
    @include phone() {
      padding-right: 0;
    }
  }
  .collection_caption,
  .logo_caption {
    margin-bottom: 60px;

    @include desktop() {
      margin-bottom: 40px;
    }
    @include phone() {
      margin-bottom: 30px;
    }
  }
  .caption_description {
    border-bottom: solid 1px $gray_light;

    > :last-child {
      padding-bottom: 40px;
    }
  }
  .more_button {
    display: block;
    width: 62px;
    @include p_little($gray_text);
    background: #f2f2f2;
    line-height: 30px;
    text-align: center;
    margin: -17px 0 0 auto;
    border-radius: 100px;
    box-shadow: 0 0 0 10px #fff;
    transition: color 0.3s, background 0.3s;

    &:before {
      content: 'More';
    }
    @include hover() {
      color: $black;
      background: #e5e5e5;
    }
  }
  .more {
    display: none;
    padding: 30px 0 40px 0;
    border-bottom: solid 1px $gray_light;

    .publish_date {
      @include p_little($gray_text);
      margin-bottom: 20px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: -10px;

      li {
        width: 50%;
        @include p_little();
        padding: 10px;
        box-sizing: border-box;

        .heading {
          font-weight: 600;
        }
        &.stat {
          width: 25%;

          .value {
            @include p();
          }
        }
      }
      &.stats {
        margin-top: 10px;
      }
    }
  }
  .tags {
    margin: -2px;

    li {
      display: inline-block;
      margin: 2px;

      a {
        display: inherit;
        @include p_little($gray);
        border: solid 2px mix(#fff, $gray_light, 50%);
        padding: 4px 10px 5px 10px;
        border-radius: 10px;
        transition:
          background 0.3s,
          border 0.3s;

        @include hover() {
          border-color: $gray_light;
        }
      }
      &.is_award {
        a {
          font-weight: 600;
        }
      }
    }
  }
  .more_toggle {
    display: none;

    &:checked + .more_button {
      &:before {
        content: 'Less';
      }
      + .more {
        display: block;
      }
    }
  }
  .comments {
    padding-top: 30px;

    .comment-bucket {
      // margin-bottom: 15px;
    }

    h6 {
      margin-bottom: 20px !important;
    }
    p {
      @include p_little();
      margin: 0;

      a {
        text-decoration: none;

        @include hover() {
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
    }
    .avatar {
      float: left;
      display: inline-block;
      vertical-align: middle;
      width: 40px;
      height: 40px;
      margin-top: 3px;
      margin-left: -40px - 20px;
      border-radius: 25%;
      box-shadow: inset 0 0 0 2px rgba(#000, 0.1);
      box-sizing: border-box;
      overflow: hidden;

      @include mobile() {
        margin-top: 5px;
        width: 30px;
        height: 30px;
        margin-left: -30px - 15px;
      }
      @include phone() {
      }
      img {
        width: 100%;
        height: auto;
        border-radius: inherit;
      }
    }
    .section-heading {
      @include label($foam);
      margin-bottom: 20px;
    }
    .article-comments {
      @include p();
      @include inner_width();
      @include spacing('margin-top', 0.5);
    }
    .comment {
      position: relative;
      margin-bottom: 20px;
      padding-bottom: 20px;
      padding-left: 40px + 20px;

      @include mobile() {
        padding-left: 30px + 15px;
      }
      .user_name {
        display: inline-block;
        font-weight: 600;
        margin-bottom: 2px;
      }
      p {
        line-height: 1.5 !important;
      }
      time {
        float: right;
        @include p_little($gray_mid);
      }
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 40px + 20px;
        right: 0;
        border-bottom: solid 1px $gray_light;

        @include mobile() {
          left: 30px + 15px;
        }
      }
      @include hover() {
        .comment-actions .icon {
          opacity: 0.5;
        }
      }
    }
    .comment-bucket :last-child .comment {
      margin-bottom: 0;

      &:before {
        display: none;
      }
    }
    .comment-actions {
      position: absolute;
      right: 0;
      top: 0;

      .icon {
        opacity: 0;
        width: 31px;
        height: 31px;
        margin: -11px;

        &-delete {
          background-color: transparent;
          background-image: image-url('../../assets/images/icon/x_gray_little.png');
          background-size: 9px auto;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          border-radius: 50%;
          transition:
            opacity 0.3s,
            background 0.3s;

          &:after,
          &:before {
            content: none;
          }
          @include hover() {
            background-color: rgba(#000, 0.05);
          }
        }
        &:hover {
          opacity: 1;
        }
      }
    }
    form {
      @include form();
      padding-top: 5px;

      button {
        display: block;
        width: 50px;
        height: 56px;
        @include hide_text();
        margin-left: auto;
        margin-right: 0px;
        margin-top: -56px;
        padding: 0 !important;
        background: transparent image-url('../../assets/images/icon/arrow_down_gray.svg')
          no-repeat 50% 50% / auto 8px !important;
        transform: rotate(-90deg);
        border-radius: 100px;
        opacity: 0.3;
        transition: opacity 0.3s;

        @include hover() {
          opacity: 1;
        }
      }
    }
  }
  .award {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .award_icon {
      width: 60px;
      height: 60px;
      margin: -5px;
      box-sizing: border-box;
      border: none;
      margin-right: 20px;

      @include phone() {
        margin-right: 15px;
      }
      img {
        visibility: hidden;
      }
      @for $i from 1 through $max_books {
        &.book-#{$i} {
          background-image: image-url('../../assets/images/icon/award/book_#{$i}.svg');
        }
      }
      @for $i from 1 through $max_master_books {
        &.master-book-#{$i} {
          background-image: image-url(
            '../../assets/images/icon/award/master_book_#{$i}.svg'
          );
        }
      }
    }
    .text {
      .label {
        display: block;
        @include label($foam);
        margin-bottom: 3px;
      }
      .title {
        display: block;
        @include p();
      }
    }
    @include hover() {
      .image {
        border-color: mix($gray, #fff, 30%);
      }
    }
  }
  .related {
    h4 {
      color: $foam;
      margin-bottom: 20px !important;
    }
    h5 {
      color: $foam;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    h6 {
      margin-top: 40px !important;
    }
    .logos {
      display: flex;
      flex-wrap: wrap;
      margin: -10px;

      @include phone_big() {
        margin: -5px;
      }
      li {
        width: 50%;
        padding: 10px;
        box-sizing: border-box;
        @include logo_list_item(2);
      }
    }
  }
}
