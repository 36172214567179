.toastContainer {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.toast {
  min-width: 250px;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-size: 0.875rem;
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  background-color: #fff;
  border: 2px solid transparent;
}

.success {
  border-color: #4caf50;
  background-color: #e9faea;
}

.error {
  border-color: #f44336;
  background-color: #fae9e9;
}

.info {
  border-color: #2196f3;
}

.closeButton {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding-left: 1rem;
  font-size: 1rem;
  img {
    height: 12px;
    width: 12px;
  }
}

@keyframes toastInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.toast-enter {
  animation: toastInRight 0.3s ease-out;
}

.toast-exit {
  animation: fadeOut 0.5s ease-out;
}
