.explore {
  .promo_banner_outer {
    padding: 20px 20px 0 20px;

    @include mobile() {
      padding: 10px 10px 0 10px;
    }
    text-align: center;

    .promo_banner img {
      display: inline-block;
      max-width: 100%;
      height: auto;
    }
  }  

  .tabs_outer {
    margin-top: 15px;
  }
}
