.profiles_outer {
  .caption {
    @include p($gray_text);
  }

  ul {
    margin: -20px 0 0 0;

    li {
      padding: 20px 0;

      + li {
        position: relative;

        &:before {
          // line
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 80px;
          right: 0;
          border-top: solid 1px #f2f2f2;

          @include phone() {
            left: 70px;
          }
        }
      }
    }
    a {
      @include caption_heading();

      @include hover() {
        .title {
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
    }
  }
}
