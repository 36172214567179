body.award {
  .content_outer {
    @include spacing('padding-top', 0.5);
    @include spacing('padding-bottom');
  }
  .award_summary {
    @include spacing('margin-bottom', 0.5);

    @include phone() {
      margin-bottom: 40px;
    }
    .award_icon {
      display: inline-block;
      vertical-align: middle;
      width: 120px;
      height: 120px;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% auto;

      @include laptop() {
        width: 100px;
        height: 100px;
      }
      @include phone_big() {
        width: 80px;
        height: 80px;
      }
      @include phone() {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      @for $i from 1 through $max_books {
        &.book-#{$i} {
          background-image: image-url('../../assets/images/icon/award/book_#{$i}.svg');
        }
      }
      @for $i from 1 through $max_master_books {
        &.master-book-#{$i} {
          background-image: image-url('../../assets/images/icon/award/master_book_#{$i}.svg');
        }
      }
    }
    .award_caption {
      @include phone(false) {
        display: inline-block;
        vertical-align: middle;
        margin-left: 25px;
      }
      @include phone() {
        margin-top: 20px;
      }
      h1 {
        margin-bottom: 0 !important;

        span {
          display: block;
        }
        .award_title {
          @include h_b();

          @include mobile(false) {
            margin-bottom: 10px !important;
          }
        }
        .heading_credits {
          // p
          @include p($gray_text);
          font-weight: normal;
          margin-top: 5px;

          a {
            font-weight: 600;
            transition: color 0.3s;

            @include hover() {
              color: $black;
              text-decoration: underline;
              text-underline-offset: 2px;
            }
          }
        }
      }
    }
  }
}
