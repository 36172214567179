body.checkout {
  .checkout_summary {
    @include clear();
    @include spacing('margin-bottom', 0.5);

    .desc {
      float: left;

      .label {
        @include h_c();
      }
      .expire_date {
        @include p($gray_mid);
        margin-top: 5px;
      }
    }
    .amount {
      float: right;
      @include h_c();
    }
  }
  .content_yearly_plan {
    background: $mint;
  }
  .hide_radio {
    label:before, label:after {
      display: none;
    }
  }
  .sticky_content.account_info {
    border-radius: 15px;
    background: $mint;
    padding: 50px 60px;
    box-sizing: border-box;

    @include mobile() {
      position: relative;
      padding: 50px;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 700px) {
        @include spacing('margin-left');
        @include spacing('margin-right');
      }
    }
    @include phone() {
      padding: 40px 30px;
    }
    h6 {
      @include label($gray);
      margin-bottom: 30px;
    }
    ul {
      li {
        @include p();

        small {
          @include label($foam);
          display: block;
          margin-bottom: 0;
          padding-bottom: 10px;
        }
        + li {
          margin-top: 10px;
        }
        &:not(:first-child) small {
          padding-top: 20px;
        }
      }
    }
  }
  .non_sticky_content {
    > section {
      border-radius: 15px;

      > label,
      > .group {
        .info {
          display: flex;
          justify-content: space-between;
        }
        .expire_date {
          @include p($gray_mid);
          margin-top: 5px;
        }
        display: block;
        position: relative;
        @include h_d();
        border-radius: 15px;
        border: solid 4px #f2f2f2;
        padding: 35px 40px 35px 80px;

        @include phone() {
          padding-left: 70px;
          padding-right: 30px;
        }
        &:before {
          content: '';
          position: absolute;
          top: calc(50% - 10px);
          left: 40px;
          border-radius: 100%;
          border: solid 2px mix(#000, #fff, 20%);
          width: 20px;
          height: 20px;
          box-sizing: border-box;
          transition: border 0.3s;

          @include phone() {
            left: 30px;
          }
        }
        &:after {
          content: '';
          position: absolute;
          top: calc(50% - 5px);
          left: 40px + 5px;
          border-radius: 100%;
          width: 10px;
          height: 10px;
          transform: scale(0);
          background: $gray;
          transition:
            transform 0.3s $easeCubic,
            background 0.3s;

          @include phone() {
            left: 30px + 5px;
          }
        }
        &:hover {
          border-color: $mint;

          &:before {
            border-color: mix(#000, #fff, 50%);
          }
        }
      }

      form {
        display: none;
        padding: 0 40px 40px 40px;

        @include phone() {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
      .paypal_buttons {
        display: none;
        padding: 0 40px 0px 40px;
      }
      + section {
        margin: 10px 0;
      }
    }
    > input[type='radio'] {
      display: none;

      &:checked + * + section {
        border: solid 4px $mint;

        > label {
          border: none;

          &:before {
            border-color: mix(#000, #fff, 30%);
          }
          &:after {
            transform: scale(1);
            background: $gray;
          }
          &:hover:before {
            border-color: mix(#000, #fff, 50%);
          }
        }
        form {
          display: block;
        }
        .paypal_buttons {
          display: block;
        }
      }
    }
  }
  main > article {
    padding-bottom: 0 !important;
  }
}
.renew_form,
.gift_card_form {
  @include form();
}