.search_advanced {
  position: relative;
  @include form();
  display: none;
  background: #fff;
  @include user_select_none();

  .search_advanced_inner {
    @include inner_width_wide();
    padding: 40px 0 0 0;
    font-size: 0;

    @include desktop(false) {
      padding-left: 80px;
      padding-right: 80px;
    }
    @include phone() {
      padding-top: 30px;
      padding-bottom: 15px;
    }
    .label {
      @include label($foam);
      margin-bottom: 20px;

      @include phone() {
        margin-bottom: 15px;
      }
    }
  }
  .filter_fields {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
    @include hide_scrollbars();
    margin: -10px -60px;
    padding: 0 50px;

    @include phone() {
      margin: -5px -35px;
      padding: 0 30px;
    }
    .group {
      &:not(.button_group) {
        width: 25%;
        padding: 10px;
        box-sizing: border-box;

        @include phone() {
          width: 100%;
          padding: 5px 5px 10px 5px;
        }
      }
      &.button_group {
        width: 50%;
        min-width: 350px;
        padding: 10px 10px 20px 10px;
        box-sizing: border-box;

        @include phone() {
          width: 100%;
          padding: 5px 5px 10px 5px;
        }
        @media ( max-width: 450px ) {
          min-width: calc(100vw - 50px);
        }
      }
    }
    &.with_buttons {
      margin-bottom: 0;
      scroll-snap-type: x mandatory;

      @include phone() {
        margin-bottom: 5px;
      }
      .group {
        scroll-snap-align: center;
      }
    }
    + .filter_fields {
      // second row
      flex-wrap: wrap;

      @include phone(false) {
        display: inline-flex;
        vertical-align: top;
        width: calc(100% - 64px + 7px);
        margin-right: 10px;
      }
      @include phone() {
        margin-bottom: 5px;
      }
    }
  }
  .submit_outer {
    @include inner_width_wide();

    @include desktop(false) {
      padding-left: 80px;
      padding-right: 80px;
    }
    @include mobile_menu() {
      position: sticky;
      bottom: 0;
      z-index: 1;
    }
    &:before {
      // top and bottom white gradient to allude to scrollability

      @include phone() {
        content: '';
        position: absolute;
        bottom: -30px;
        left: 0;
        height: 110px;
        width: 100%;
        background: linear-gradient(
          to bottom,
          rgba(#fff, 0) 0px,
          rgba(#fff, 1) 20px,
          rgba(#fff, 1) 100%
        );
        pointer-events: none;
      }
    }
  }
  button {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 64px;
    height: 50px;

    @include phone(false) {
      display: block;
      height: 64px;
      margin: -7px;
      border-radius: 100%;
      margin-left: auto;
      margin-top: -50px - 7px;
      @include hide_text();
      background-color: $orange;
      background-image: image-url('../../assets/images/icon/search.png'),
        image-url('../../assets/images/layout/orange_button_bg.png');
      background-size:
        28px auto,
        100% 100%;
      background-position:
        50% 50%,
        0 0;
      background-repeat: no-repeat;

      @include desktop(false) {
        height: 70px;
        margin-top: -50px - 10px;
        margin-bottom: -10px;
      }
      @include mobile_menu() {
        margin-top: -50px;
      }
      @include hover() {
        background-image: image-url('../../assets/images/icon/search.png'),
          image-url('../../assets/images/layout/transparent.gif');
        background-size:
          28px auto,
          100% 100%;
        background-position:
          50% 50%,
          0 0;
      }
    }
    @include phone() {
      width: 100%;
    }
  }
  label,
  .label {
    display: block;
    @include p_little($gray_text);
    margin-bottom: 10px;
    transition: color 0.3s;
  }
  .filter_buttons {
    display: flex;
    @include phone_big() {
    }
    @include phone() {
    }
    li {
      width: 33.333%;
      padding: 0;
      box-sizing: border-box;

      &:first-child {
        width: calc(33.333% + 2px);
        margin-right: -2px;

        .filter_button {
          border-radius: 15px 0 0 15px;
        }
      }
      &:nth-child(2) {
        .filter_button {
          border-radius: 0;
        }
      }
      &:last-child {
        width: calc(33.333% + 2px);
        margin-left: -2px;

        .filter_button {
          border-radius: 0 15px 15px 0;
        }
      }
    }
    .filter_button {
      // label
      position: relative;
      text-align: center;
      width: 100%;
      height: 100px;
      @include p($gray_text);
      white-space: nowrap;
      line-height: 132px !important;
      box-shadow: inset 0 0 0 2px $gray_light;
      margin-bottom: 0;
      border-radius: 10px;
      transition:
        box-shadow 0.3s,
        background 0.3s,
        color 0.3s;
      cursor: default;

      @media (max-width: 960px) {
        font-size: 15px;
      }
      @media (max-width: 800px) {
        font-size: 14px;
      }
      @include phone() {
        // height: 80px;
        // line-height: 108px !important;
      }
      &:before {
        content: '';
        position: absolute;
        top: 25px;
        left: calc(50% - 10.5px);
        width: 21px;
        height: 21px;
        background: image-url('../../assets/images/icon/award_gray.png') no-repeat 0 0/100%
          auto;
        opacity: 0.2;
        transition: opacity 0.3s;

        @include phone() {
          // top: 18px;
        }
      }
      &:after {
        // x button
        content: '';
        position: absolute;
        top: 10px;
        right: 10px;
        width: 23px;
        height: 23px;
        background: image-url('../../assets/images/icon/x_gray_little.png') no-repeat 50%
          50%/9px auto;
        border-radius: 100%;
        opacity: 0;
        transition:
          opacity 0.3s,
          transform 0.3s $easeCubic;
      }
      &.ranked:before {
        background-image: image-url('../../assets/images/icon/spark_gray.png');
      }
      &.collected:before {
        background-image: image-url('../../assets/images/icon/collection_gray.png');
      }
      &.typographic:before {
        background-image: image-url('../../assets/images/icon/typographic_gray.png');
      }
      &.symbols:before {
        background-image: image-url('../../assets/images/icon/symbols_gray.png');
      }
      &.combos:before {
        background-image: image-url('../../assets/images/icon/combos_gray.png');
      }
      @include hover() {
        box-shadow: inset 0 0 0 2px mix($gray, $gray_light, 10%);

        &:before {
        }
      }
      &:focus-visible {
        box-shadow: inset 0 0 0 2px $gray;
      }
    }
    .filter_button_checkbox {
      display: none;

      &:checked {
        + .filter_button {
          z-index: 1;
          color: $gray;
          box-shadow: inset 0 0 0 4px $foam;

          &:before {
            opacity: 1;
          }
          &:after {
            opacity: 0.3;
          }
          @include hover() {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.search_outer .search_advanced {
  position: fixed;
  z-index: 200;
  top: 80px;
  left: 0;
  right: 0;
  border-bottom: 0;

  @include desktop() {
    top: 70px;
  }
  @include mobile_menu() {
    max-height: calc(100vh - 70px);
    overflow-y: auto;

    @media (max-height: 616px) {
    }
  }
  .search_advanced_inner {
    overflow: hidden;

    @include mobile_menu() {
      @media (max-height: 616px) {
      }
    }
    @include phone() {
    }
  }
  &:before {
    // top and bottom white gradient to allude to scrollability

    @include phone() {
      content: '';
      position: sticky;
      display: block;
      z-index: 2;
      top: 0;
      // bottom: 80px;
      left: 0;
      height: 30px;
      margin-bottom: -30px;
      width: 100%;
      background: linear-gradient(
        to bottom,
        rgba(#fff, 1) 0px,
        rgba(#fff, 1) 10px,
        rgba(#fff, 0) 30px
      );
      pointer-events: none;
    }
  }
}
