body.submit {
  main .content_outer {
    @include spacing('padding-bottom');
    @include inner_width_wide();

    @include phone() {
      padding-top: 30px;
    }
    h1 {
      display: none;
    }
  }
  .logos_outer {
    margin-top: 30px;
  }
}
.upload_form {
  // form
  @include form();

  @include phone_big(false) {
    display: flex;
    align-items: flex-start;
  }
  label,
  .label {
    display: block;
    @include p($gray_text);
  }
  .image_group {
    position: relative;
    width: 100%;

    @include phone_big(false) {
      width: calc(50% - 40px);
      margin-right: 80px;

      @include mobile() {
        width: calc(50% - 30px);
        margin-right: 60px;
      }
    }
    .upload-preview {
      position: relative;
      padding-top: 100%;
      width: 100%;
    }
    .upload-bucket {
      // #drop-bucket
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 5%;
      border-radius: 15px;
      box-sizing: border-box;
      border: solid 4px #f2f2f2;

      img {
        height: 100%;
        object-fit: contain;
      }
      &.is-drag-over {
        border-color: $mint;
      }
      &:before {
        content: 'Upload your logo.';
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        width: 5em;
        @include h_b($foam);
        text-align: center;
        transform: translate(-50%, calc(-50% - 40px));
      }
      &.no-before:before {
        display: none;
      }
    }
    label {
      position: absolute;
      bottom: 25px;
      left: 23px;
      right: 23px;
      text-align: center;

      strong {
        display: inline-block;
        @include button(#f2f2f2);
        margin: 5px 7px;
      }
      span {
        display: inline-block;
        margin: 5px 7px;
      }
    }
    input[type='file'] {
      display: none;
    }
    &.not_empty {
      label {
        display: none;
      }
    }
  }
  .checkbox_outer {
    display: flex;
    margin: 0;

    label {
      width: calc(33.333% + 1px);
      margin-right: -1px;
      box-sizing: border-box;
      background: image-url('../../assets/images/icon/symbols_gray_light.png') no-repeat
        50% 16px/21px auto;
      border: solid 2px $gray_light;
      border-radius: 15px 0 0 15px;
      text-align: center;
      padding: 46px 10px 45px 10px;

      &:before {
        top: auto;
        bottom: 15px;
        left: calc(50% - 10px);
      }
      &:after {
        top: auto;
        bottom: 15px + 5px;
        left: calc(50% - 10px + 5px);
      }
      &[for='logo-checkbox-symbol'] {
      }
      &[for='logo-checkbox-typographic'] {
        width: calc(33.333% + 2px);
        margin: 0 -1px;
        border-radius: 0;
        background-image: image-url(
          '../../assets/images/icon/typographic_gray_light.png'
        );
      }
      &[for='logo-checkbox-combo'] {
        margin-left: -1px;
        border-radius: 0 15px 15px 0;
        background-image: image-url('../../assets/images/icon/combos_gray_light.png');
      }
      @include hover() {
        border-color: $mint;
      }
    }
  }
  .data_group {
    width: 100%;

    @include phone_big(false) {
      width: calc(50% - 40px);

      @include mobile() {
        width: calc(50% - 30px);
      }
    }
    @include phone_big() {
      margin-top: 30px;
    }
    > fieldset:first-child label {
      margin-top: 0;
    }
    .group {
      display: flex;
      margin: -10px;

      fieldset {
        width: 50%;
        padding: 10px;
        box-sizing: border-box;
      }
    }
    label.required::after {
      content: ' *';
      color: red;
    }
  }
  fieldset.has_more_credits_toggle {
    @include clear();

    .more_credits_toggle {
      float: right;
      @include p_little();
      padding-top: 2px;
      transition: color 0.3s;

      &:hover {
        color: $orange;
      }
    }
  }
  .more_credits {
    display: none;
  }
  #more_credits_toggle {
    display: none;

    &:checked {
      + .data_group {
        .more_credits_toggle {
          display: none;
        }
        .more_credits {
          display: block;
        }
      }
    }
  }

  .container_button_outter {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    button {
      cursor: pointer;
      &.submit {
        margin-left: auto;
      }
    }
  }
}
