body.books {
  .banner {
    overflow: hidden;
    margin-bottom: 15px;
    background-position: 100% 1%;
    background-size: auto 300%;
    background: $mint; // :)

    .banner_inner {
      position: relative;
      max-width: 1360px;
      @include spacing('padding-left');
      @include spacing('padding-right');
      margin: 0 auto;

      @include desktop() {
        max-width: 1200px;
      }
      @include mobile(false) {
        text-align: left;
      }
      @include mobile() {
        padding-bottom: 0 !important;
      }
      > * {
        max-width: none;

        @include mobile(false) {
          padding-left: 0 !important;
          @include spacing('margin-right');
          padding-right: 50%;
        }
        @include mobile() {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
    .book_image {
      // book image
      padding: 0;
      border-radius: 5px 5px 0 0;
      background-color: #fff;
      background-image: linear-gradient(
          180deg,
          rgba(#000, 0) 0%,
          rgba(#000, 0) 75%,
          rgba(#000, 0.05) 100%
        ),
        linear-gradient(
          90deg,
          rgba(#000, 0) 1.667%,
          rgba(#000, 0.05) 3.333%,
          rgba(#000, 0) 5%
        ),
        image-url('../../assets/images/temp_book_cover.jpg');
      background-repeat: no-repeat;
      background-position:
        0 0,
        0 0,
        10px 0;
      background-size:
        100% 100%,
        100% 100%,
        calc(100% - 10px) auto;
      box-shadow: 0 100px 100px rgba(mix($foam, #000, 50%), 0.3);
      transform-origin: 0 calc(100% - 50px);
      transform: translate(0, 0) rotate(10deg);
      transition:
        top 1s $easeCubic,
        transform 1s $easeCubic;

      @include mobile(false) {
        position: absolute;
        top: 60px;
        bottom: -50px;
        left: 50%;
        @include spacing('right');
      }
      @include mobile() {
        width: 70%;
        padding-top: 75%;
        margin: 0 auto;
        @include spacing('margin-top', 0.75);
        margin-bottom: -50px;
      }
      @include phone_little() {
        height: auto;
      }
      @include hover() {
        @include mobile(false) {
          transform: translate(0, -50px) rotate(10deg);
        }
      }
    }
  }
  .tabs_outer {
    .tabs {
      margin-top: 0 !important;
    }
  }
  .books_group_outer {
    @include spacing('padding-top', 0.75);
    @include spacing('padding-bottom', 0.75);
    @include spacing('padding-left');
    @include spacing('padding-right');

    h2 {
      @include h_b($foam);
      @include spacing('margin-bottom', 0.75);
    }
    + .books_group_outer {
      padding-top: 0 !important;
    }
  }
  .books_group {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    @include mobile() {
      gap: 30px;
    }
    @include phone() {
      gap: 10px;
    }
    article {
      width: calc(20% - (40px * 4 / 5));
      padding: 40px;
      padding-bottom: 30px;
      box-shadow: inset 0 0 0 2px #f2f2f2;
      border-radius: 20px;
      box-sizing: border-box;

      @include desktop() {
        width: calc(25% - (40px * 3 / 4));
      }
      @include laptop() {
        width: calc(33.333% - (40px * 2 / 3));
      }
      @include mobile() {
        width: calc(50% - (30px * 1 / 2));
      }
      @include phone() {
        width: calc(50% - (10px * 1 / 2));
        padding: 20px;
      }
      figure {
        padding-bottom: 20px;

        @include phone() {
          padding-bottom: 15px;
        }
        a {
          img {
            display: block;
            width: 100%;
            max-width: 180px;
            height: auto;
            margin: auto;
          }
        }
      }
      .text_content {
        text-align: center;

        h2 {
          @include h_d();
          margin: 0 0 5px 0;
          left: 0;
        }
        ul.text_meta {
          li {
            @include p_little($gray_mid);

            &:nth-child(2) {
              display: none;
            }
          }
        }
        p {
          display: none;
        }
        .button_outer {
          text-align: center;
        }
        .button {
          display: inline-block;
          box-sizing: border-box;
          text-align: center;
          @include p();
          font-weight: bold;
          margin-top: 10px;
          padding: 12px;
          padding-left: 20px + 8px + 10px;
          padding-right: 20px;
          background: image-url('../../assets/images/layout/arrow_tiny_gray.svg')
            no-repeat 20px 50%/8px auto;
          border-radius: 10px;
          transition: background 0.3s;

          @include phone() {
            padding-left: 15px + 8px + 10px;
            padding-right: 15px;
            background-position-x: 15px;
          }
          @include hover() {
            background-color: $mint;
          }
        }
      }
    }
    &:first-child article {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        figure a img {
          transform: scale(1.25);
          mix-blend-mode: multiply;
        }
      }
    }
  }
}
