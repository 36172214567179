// Mixins

@mixin search_field_light() {
  background-color: #fff;
  color: $gray;
  background-image: image-url('../../assets/images/icon/search_gray.png');

  &::placeholder {
    color: rgba($gray, 0.3);
  }
}

// Styles

.search_outer {
  @include mobile_menu() {
  }
  fieldset {
    position: fixed;
    z-index: 201;
    top: 10px;
    left: 30%;
    width: 40%;
    font-size: 0;
    transform: translateY(-80px);
    transition: transform 0.3s $easeCubic;

    @include desktop() {
      transform: translateY(-70px);
    }
    @include mobile_menu() {
      top: 0;
      left: 0;
      width: 100%;
      transform: none;
      @include spacing('margin-left');
      @include spacing('margin-right');
    }
    @include phone() {
      margin-left: 10px;
      margin-right: 10px;
    }
    input[type='search'] {
      width: 100%;
      border-radius: 7px;
      @include p(#fff);
      font-family: $heading_font;
      letter-spacing: 0;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      background: mix($gray, #fff, 90%) image-url('../../assets/images/icon/search.png')
        no-repeat 22px 50%/21px auto;
      height: 60px;
      padding: 0 22px;
      transition:
        background 0.3s,
        color 0.3s;

      @include desktop() {
        height: 50px;
        font-size: 22px;
      }
      @include mobile() {
        font-size: 20px;
      }
      &::placeholder {
        position: relative;
        top: -2px;
        color: rgba(#fff, 0.5);
        font-family: $paragraph_font;
        font-size: 20px;
        font-weight: 400;
        transition: color 0.3s;

        @include desktop() {
          font-size: 18px;
          top: -1px;
        }
        @include mobile() {
          font-size: 16px;
        }
      }
      @include hover() {
        background-color: mix($gray, #fff, 80%);

        &::placeholder {
          color: #fff;
        }
      }
      &.valueless,
      &:focus {
        @include search_field_light();
      }
    }
    button[type='submit'] {
      display: none;
    }
    .search_advanced_toggle {
      display: none !important;
      position: absolute;
      top: 0;
      right: 0;
      width: 60px + 14px;
      height: 60px;
      background: red;
      border-radius: 7px;
      @include hide_text();
      background: image-url('../../assets/images/icon/filter.png') no-repeat 50% 50%/21px
        auto;
      opacity: 0.4;
      transition:
        background 0.3s,
        opacity 0.3s;
      cursor: default;

      @include desktop() {
        width: 50px + 14px;
        height: 50px;
      }
      @include hover() {
        opacity: 1;
        background-color: rgba(#fff, 0.1);
      }
    }
    .close_search_button {
      position: absolute;
      top: calc(50% - 15.5px);
      @include spacing('right');
      width: 31px;
      height: 31px;
      border-radius: 100%;
      background: $gray_light image-url('../../assets/images/icon/x_gray_little.png')
        no-repeat 50% 50%/9px auto;
      @include hide_text();
      transition:
        background 0.1s,
        transform 0.1s $easeCubic;

      @include desktop(false) {
        right: 80px;
      }
      @include mobile_menu(false) {
        display: none;
      }
      @include phone() {
        right: 25.5px;
      }
      @include hover() {
        background-color: mix($gray, $gray_light, 20%);
      }
    }
  }
}
.search_outer_shade {
  position: fixed;
  z-index: 310;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  background: rgba(#000, 0.2);
  transition: opacity 0.5s;

  @include mobile() {
    background: rgba(#000, 0.4);
  }
  &.open {
    opacity: 1;
    pointer-events: auto;
  }
}

.search_outer {
  @include mobile_menu() {
    display: none;
    @include spacing('margin-right', -1);
    @include spacing('margin-left', -1);
  }
}

body.search {
  // on the search results page

  .search_outer {
    @include mobile_menu() {
      display: block;
      margin-left: 0;
      margin-right: 0;
    }
    fieldset {
      @include mobile_menu() {
        position: relative;
        z-index: 2;
      }
      &:first-child {
        @include mobile_menu() {
          margin: 0;
          border-bottom: solid 1px $gray_light;
        }
        input[type='search'] {
          @include mobile_menu() {
            height: 70px;
            border-radius: 0;
            @include spacing('background-position-x');
            @include search_field_light();

            @include mobile_menu() {
              border-radius: 30px 30px 0 0 !important;
            }
            @include phone() {
              border-radius: 20px 20px 0 0 !important;
            }
          }
        }
      }
    }
    &:not(.open) {
      .close_search_button {
        @include mobile_menu() {
          display: none;
        }
      }
      fieldset:first-child {
        @include mobile_menu() {
          position: sticky;
          top: 0;
          margin-bottom: -71px;
        }
      }
      main {
        @include mobile_menu() {
          margin-top: 71px;
        }
      }
    }
  }
}
.search_outer.open {
  // Open
  display: block;

  fieldset,
  .search_advanced {
    position: fixed !important;
    display: block;
    z-index: 311 !important;
  }
  fieldset {
    z-index: 312 !important;
    transform: none !important;
    margin: 0;
    background: #fff;
    border-bottom: solid 1px #e5e5e5;
    border-radius: 20px 20px 0 0;

    @include mobile_menu(false) {
      width: 1200px;
      left: calc(50% - 600px);

      @include desktop(false) {
      }
    }
    @media (max-width: 1220px) {
      width: 100%;
      left: 0;
    }
    @include mobile_menu() {
      border-radius: 20px 20px 0 0;
    }
    input[type='search'] {
      @include search_field_light();
      transition: none;
      border-radius: inherit;
      @include spacing('background-position-x');
      height: 70px;

      @include desktop(false) {
        background-position-x: 80px;
      }
    }
    .close_search_button {
      @include mobile_menu(false) {
        display: block;
      }
    }
  }
  .search_advanced {
    display: block;
    position: fixed;
    top: 80px;
    left: calc(50% - 600px);
    width: 1200px;
    padding-bottom: 60px;
    border-radius: 0 0 30px 30px;
    box-shadow: 0 80px 60px -60px rgba(#000, 0.2);

    @include desktop(false) {
    }
    @media (max-width: 1220px) {
      width: 100%;
      left: 0;
    }
    @include mobile_menu() {
      top: 70px;
      box-sizing: border-box;
      border-radius: 0 0 20px 20px;
    }
    @include phone() {
      padding-bottom: 30px;
    }
  }
  .search_outer_shade,
  + .search_outer_shade {
    opacity: 1;
    pointer-events: auto;
  }
}
