.accordion {
  h5 {
    display: block;
    position: relative;
    margin: 0;
    padding: 15px 40px 15px 0;
    margin: 10px 0;
    cursor: pointer;

    @include mobile(false) {
      font-size: 120% !important;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 40px;
      height: 40px;
      margin-top: -20px;
      border-radius: 100%;
      background: image-url('../../assets/images/accordion_bg.gif') no-repeat 50% 50%/12px
        2px;
      transition:
        background 0.3s,
        transform 0.3s $easeCubic;

      @include phone() {
        right: -14px;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 2px;
      height: 12px;
      margin-top: -6px;
      margin-right: 19px;
      background: $gray;
      transition: transform 0.3s $easeCubic;

      @include phone() {
        right: -14px;
      }
    }
    @include hover() {
      &:before {
        background-color: $mint; // rgba(#000,0.05);
      }
    }
  }
  .accordion_content {
    position: relative;
    display: block;
    height: 1px;
    overflow: hidden;
    color: transparent;
    transition:
      height 0.3s $easeCubic,
      opacity 0.3s,
      color 0.3s;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: rgba(#000, 0.1);
    }
  }
  &.open {
    .accordion_content {
      color: $gray;
    }
    h5:after {
      transform: scaleY(0);
    }
  }
  &:last-child {
    .accordion_content:after {
      content: none;
    }
  }
}
