.banner {
  background: $mint image-url('../../assets/images/banner_mint_bg_big.png') no-repeat 0
    50% / cover; // 100vw auto;

  @include phone() {
    background-size: auto 157.9%;
    background-position: 100% 50%;
  }
  .banner_inner {
    text-align: center;
    @include spacing('padding-top', 0.75);
    @include spacing('padding-bottom', 0.75);

    h1:not(.headline) {
      @include label($gray);
      margin-bottom: 0.5em;
    }
    > * {
      max-width: 1000px;
      @include spacing('padding-left');
      @include spacing('padding-right');
      margin: 0 auto;
    }
    .headline {
      // h1
      @include h_a($gray);

      @include desktop() {
        max-width: 800px;
      }
      @include mobile() {
        max-width: 600px;
      }
      @include phone() {
        font-size: 44px;
        max-width: 400px;
      }
      @media (max-width: 400px) {
        font-size: 40px;
      }
      span {
        display: inline-block;
      }
    }
    p {
      @include p_bigger($gray);
      margin-top: 10px; // 18px;

      @include phone() {
        margin-top: 10px;
      }
      span {
        display: inline-block;
      }
      &.black {
        color: $black !important;
      }
    }
    .buttons {
      margin-top: 35px;

      @include phone() {
        margin-top: 20px;
      }
      .button + .button {
        margin-left: 10px;
      }
    }
    .button {
      @include button_big();

      span {
        opacity: 0.5;
        text-transform: uppercase;
        margin-left: 5px;
      }
      &.white {
        @include button_big(#fff);
      }
    }
  }
  &.orange {
    background-image: image-url('../../assets/images/banner_bg_big.png');

    .banner_inner {
      h1 {
        color: #fff;
      }
      p {
        color: #fff;
      }
      .button {
        @include button_big($mint);
      }
    }
  }
  &.book_10 {
    background-image: image-url('../../assets/images/banner_book_13_bg.png');

    @include mobile() {
      background-size: 200% auto;
      background-position: 100% 50%;
    }
    @include phone() {
      background-size: auto 201%;
      background-position: 100% 50%;
    }
  }
}
