.logo_collection {
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
  margin: -30px -10px;
  width: calc(100% + 10px + 10px);
  padding-top: 20px;
  padding-bottom: 20px;
  scroll-snap-type: x mandatory;
  @include hide_scrollbars();

  @include laptop(false) {
    margin-left: -80px;
    margin-right: -80px;
    padding-left: 70px;
    padding-right: 70px;
    width: calc(100% + 80px + 80px);
  }
  @include mobile() {
    @include spacing('margin-left', -1);
    @include spacing('margin-right', -1);
    padding-left: 50px - 10px;
    padding-right: 50px - 10px;
  }
  @include phone_big() {
    margin-top: -20px - 8px;
    margin-bottom: -20px - 8px;
    padding-left: 50px - 8px;
    padding-right: 50px - 8px;
    scroll-padding-left: 40px;
    width: calc(100% + 8px + 8px);
  }
  @include phone() {
    padding-left: 30px - 8px;
    padding-right: 30px - 8px;
    scroll-padding-left: 0;
  }
  @include phone_little() {
    scroll-padding-left: 50vw;
  }
  li {
    @include logo_list_item();
    scroll-snap-align: center;

    @include phone_big() {
      scroll-snap-align: start;
    }
    @include phone() {
      scroll-snap-align: center;
    }
    @include phone_little() {
      scroll-snap-align: start;
    }
    &:first-child {
      margin-left: 20%;
      &.skeleton {
        // margin-left: 0;
      }

      @include phone_big() {
        margin-left: 0;
      }
      @include phone() {
      }
      @include phone_little() {
      }
    }
    &:last-child {
      @include phone_big() {
      }
      @include phone() {
      }
    }
    .logo_outer {
      background: #fff;
      box-shadow: 0 0 0 20px #fff;
    }
    .logo {
      @include logo();
    }
    &.square_button_outer {
      position: relative;
      padding-top: calc(20% - 10px);

      @include phone_big() {
        padding-top: calc(25% - 8px);
      }
      @include phone() {
        padding-top: calc(33.333% - 8px);
      }
      @include phone_little() {
        padding-top: calc(50% - 8px);
      }
      a.square_button {
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
        @include p();
        font-weight: 600;
        line-height: 1.2;
        padding: 25px 30px;
        margin-top: 0 !important;
        border-radius: 15px;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-size:
          30px auto,
          101% 100%;
        background-position:
          calc(100% - 30px) calc(100% - 30px),
          50% 50%;
        background-image: image-url('../../assets/images/layout/arrow_right.png'),
          image-url('../../assets/images/layout/mint_button_bg.png');
        transition:
          color 0.3s,
          background 0.3s,
          box-shadow 0.3s;
        @include user_select_none();

        @include laptop() {
        }
        @include phone_big() {
          top: 8px;
          bottom: 8px;
          left: 8px;
          right: 8px;
        }
        .count {
          display: block;
          color: #fff;
          font-size: 1.6em;
        }
        @include hover() {
          background-image: image-url('../../assets/images/layout/arrow_right.png'),
            image-url('../../assets/images/layout/orange_button_bg.png');
        }
      }
    }
  }
  &.is_start + .scroll_button.left_button {
    opacity: 0;
    transform: scale(0) rotate(180deg);
    pointer-events: none;
  }
  &.is_end + * + .scroll_button.right_button {
    opacity: 0;
    transform: scale(0);
    pointer-events: none;
  }
}
.collection_group {
  position: relative;
  font-size: 0;

  @include phone_big(false) {
    display: flex;
    align-items: center;
  }
  .collection_summary {
    display: block;

    @include phone_big(false) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 20%;
      padding-right: 40px;
      box-sizing: border-box;
    }
    @include phone_big() {
      padding-bottom: 30px;
    }

    .award_icon {
      width: 90px;
      height: 90px;
      margin-bottom: 20px;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% auto;

      @include mobile() {
        width: 70px;
        height: 70px;
      }
      @include phone_big() {
        margin: -5px;
        margin-right: 15px;
      }
      @include phone() {
        width: 60px;
        height: 60px;
      }
      @for $i from 1 through $max_books {
        &.book-#{$i} {
          background-image: image-url('../../assets/images/icon/award/book_#{$i}.svg');
        }
      }
      @for $i from 1 through $max_master_books {
        &.master-book-#{$i} {
          background-image: image-url(
            '../../assets/images/icon/award/master_book_#{$i}.svg'
          );
        }
      }
    }
    .award_title {
      @include h_d();
    }
    &:has(.award_icon) {
      @include phone_big() {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .caption {
      @include p_little($gray_text);
      margin-top: 3px;
    }
  }
  .scroll_button {
    position: absolute;
    z-index: 3;
    top: calc(50% - 30px);
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: #fff;
    background-size: 20px auto;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: image-url('../../assets/images/layout/arrow_right_gray.png');
    box-shadow:
      0 0 2px rgba(#000, 0.05),
      0 10px 20px rgba(#000, 0.05);
    transition:
      background 0.3s,
      opacity 0.3s $easeCubic,
      transform 0.3s $easeCubic;

    &.left_button {
      left: -100px;
      box-shadow:
        0 0 2px rgba(#000, 0.05),
        0 -10px 20px rgba(#000, 0.05);
      transform: scale(1) rotate(180deg);
    }
    &.right_button {
      right: -100px;
    }
    @include hover() {
      background-color: $gray_light;
    }
    @media (max-width: 1233px) {
      display: none;
    }
  }
  + .collection_group {
    padding-top: 40px;
    margin-top: 40px;
    border-top: solid 1px $gray_light;

    .collection_summary {
      top: calc(50% + 20px);
    }
    .scroll_button {
      top: calc(50% - 10px);
    }
  }
  &.is_award {
  }
  &:before {
    @include laptop(false) {
      content: '';
      position: absolute;
      z-index: 2;
      left: -80px;
      top: 0;
      bottom: 0;
      width: 80px;
      background: linear-gradient(to left, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%);
    }
  }
  &:after {
    @include laptop(false) {
      content: '';
      position: absolute;
      z-index: 2;
      right: -80px;
      top: 0;
      bottom: 0;
      width: 80px;
      background: linear-gradient(to right, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%);
    }
  }
}
.collection_cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;

  @include phone_big() {
    gap: 20px;
  }
}
.collection_card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: calc(33.333% - (30px * 2 / 3));
  border-radius: 20px;
  box-shadow: inset 0 0 0 2px rgba(#000, 0.1);
  transition: box-shadow 0.3s;

  @include laptop() {
    width: calc(50% - (30px * 1 / 2));
  }
  @include mobile() {
    border-radius: 15px;
  }
  @include phone_big() {
    width: calc(50% - (20px * 1 / 2));
  }
  @include phone() {
    width: 100%;
  }
  span {
    display: block;
  }
  .logo_grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 15px;
    padding: 40px;

    @include phone_big() {
      padding: 30px;
    }
    > span {
      width: calc(50% - (15px * 1 / 2));
      box-sizing: border-box;

      > span {
      }
      img {
        position: relative;
        z-index: -1;
        width: 100%;
        height: auto;
      }
    }
  }
  .collection_summary {
    padding: 0 40px 40px 40px;

    @include phone_big() {
      padding: 0 30px 30px 30px;
    }
    .title {
      h5 {
        @include h_d();
        margin: 0 0 5px 0;
      }
    }
    .caption {
      @include p_little($gray_text);
      margin-top: 3px;
    }
  }
  @include hover() {
    box-shadow: inset 0 0 0 2px rgba(#000, 0.2);
  }
}
