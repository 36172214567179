body.join {

  #more_fields_toggle {

    display: none;



    + form {

      .more_fields {

        display: none;

      }

    }

    &:checked + form {

      .has_more_fields_button {

        display: none;

      }

      .more_fields {

        display: block;

      }

    }

  }

  .button_outer + fieldset {

    margin-top: 40px;

  }

}

.form-join {

  @include form();



  fieldset:first-of-type {

    padding-top: 40px;

    border-top: solid 1px $gray_light;



    @include phone() {

    }

    legend {

      padding: 10px;

      margin: -10px;

      margin-bottom: -40px;

    }

  }

  fieldset + fieldset {

    margin-top: 40px;

  }

  .form-country-message {

    font-style: italic;

    font-size: 0.7em !important;

    color: #ed6213 !important;;

  }

}
