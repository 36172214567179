
.all_logos_button {
  @include button($mint);
  margin: 10px auto;
}

.page_header_outer {
  height: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  @include inner_width_wide();

  @include desktop() {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @include mobile() {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @include phone() {
  }
  h1 {
    display: block;
  }
}
.page_header {
  position: relative;

  @include mobile(false) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding-left: 120px;
    min-height: 80px;
  }  
  .profile_image {
    // a
    position: relative;
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto 20px auto;
    border-radius: 10px;
    box-shadow: inset 0 0 0 2px rgba(#000, 0.1);
    // transition: box-shadow 0.3s;
    // overflow: hidden;

    @include mobile(false) {
      position: absolute;
      top: 0;
      left: 0;
    }
    @include mobile() {
      margin-bottom: 25px;
    }
    &.skeleton {
      box-shadow: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
      border-radius: inherit;
      overflow: hidden;
    }
    /*
		@include hover() {
			box-shadow: inset 0 0 0 2px mix($gray,#fff,30%);
		}
*/
    &.is_award_winner {
      @include mobile() {
        margin-bottom: 40px;
      }
      &:before {
        content: '';
        position: absolute;
        bottom: -37.5%;
        right: -37.5%;
        width: 75%;
        height: 75%;
        background: image-url('../../assets/images/icon/award/award_winner_badge.svg')
          no-repeat 0 0/100% auto;
      }
    }
  }
  h1.name {
    // h1
    // display: block;
    @include h_c($gray);
    // font-weight: 600;
    margin: 0 !important;

    @include mobile(false) {
      // padding-top: 5px;
    }
    &.skeleton {
      width: 180px;
      height: 20px;
      margin-bottom: 8px;
      border-radius: 100px;
      background: linear-gradient(to right, #eee 25%, #f5f5f5 50%, #eee 75%);
      background-size: 200% 100%;
      animation: skeleton-loading 1.5s infinite linear;

      @include mobile() {
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }
  .info {
    @include p($gray_text);
    margin-top: 5px;

    @include mobile() {
      // margin-top: 15px;
    }
    > * {
      display: inline-block;
    }
    a {
      font-weight: 600;
      transition: color 0.3s;

      @include hover() {
        color: $black;
        text-decoration: underline;
        text-underline-offset: 2px;
        // color: $orange;
      }
    }
    &.skeleton {
      width: 90px;
      height: 13px;
      margin-top: 15px;
      border-radius: 100px;
      background: linear-gradient(to right, #eee 25%, #f5f5f5 50%, #eee 75%);
      background-size: 200% 100%;
      animation: skeleton-loading 1.5s infinite linear;

      @include mobile() {
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }
  .page_actions {
    @include mobile(false) {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    @include mobile() {
      margin-top: 25px;
    }
    a,
    label {
      + a,
      + label {
        // second
        @include button_white();
        margin-left: 10px;
      }
      &.follow_button {
        @include button($mint);
      }
      &.gray_button {
        @include button_little(#eeeeee);
      }
    }
    .gray_button {
      @include button_little(#eeeeee);
    }
  }
  .follow_toggle {
    display: none;

    &:checked + .page_actions {
      .follow_button {
        color: $gray_text;
        background: #fff;
        box-shadow: inset 0 0 0 2px rgba(#000, 0.1);

        &:after {
          content: 'ing';
        }
      }
    }
  }
  /*
	.stats {
		@include p_little();
		margin-top: 5px;
	}
*/
}
section.about_profile {
  .content_outer {
    @include phone_big(false) {
      display: flex;
    }
  }
  .about_section {
    p a:not(:hover) {
      text-decoration: none;
    }
    .social_icons {
      margin: -15px;
      margin-top: -5px;
      margin-bottom: 5px;
      font-size: 0;

      a {
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 50px;
        @include hide_text();
        background-repeat: no-repeat;
        background-size: 20px auto;
        background-position: 50% 50%;
        border-radius: 100%;
        transition: background 0.3s;

        &.facebook {
          background-image: image-url('../../assets/images/social/facebook_gray.svg');
        }
        &.twitter {
          background-image: image-url('../../assets/images/social/twitter_gray.svg');
        }
        &.instagram {
          background-image: image-url('../../assets/images/social/instagram_gray.svg');
        }
        &.dribbble {
          background-image: image-url('../../assets/images/social/dribbble_gray.svg');
        }
        &.behance {
          background-image: image-url('../../assets/images/social/behance_gray.png');
        }
        &.linkedin {
          background-image: image-url('../../assets/images/social/linkedin_gray.svg');
        }
        @include hover() {
          background-color: $mint;
        }
      }
    }
    &.left {
      @include phone_big(false) {
        width: 72%;
        padding-right: 80px;
        box-sizing: border-box;
      }
    }
    &.right {
      @include phone_big(false) {
        width: 28%;
      }
      @include phone_big() {
        margin-top: 40px;
      }
    }
  }
}
