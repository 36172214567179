.support_form {
  @include form();
  margin-top: 40px;

  @include desktop() {
    margin-top: 30px;
  }
  .field_message {
    background: $mint;
    padding: 16px 20px;
    margin-top: 30px;

    &.hidden {
      display: none;
    }
  }
  textarea {
    height: 120px;
    resize: vertical;
  }
}
