// Dark gray for solid areas and text
$gray: #474747;
$black: $gray;
$gray_mid: mix($gray, #fff, 50%);
$gray_text: mix($gray, #fff,70%);

// Light gray for lines
$gray_light: #e5e5e5; // 10% black

// Gradiate between orange and red
$orange: #ed6213;
$red: #e73e18;

// Gradiate between mint and foam
$mint: #ddeecd;
$foam: #added5;

// Fonts

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
/*
@import url('https://fonts.googleapis.com/css2?family=Calistoga&display=swap');
@import url("https://use.typekit.net/rqw4yzp.css");
*/

$heading_font: "Inter", Helvetica, Arial, sans-serif;
// $heading_font: jubilat, serif;
// $heading_font: 'Calistoga', serif;
$paragraph_font: "Inter", Helvetica, Arial, sans-serif; // -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif; // "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"

::selection {
	background: rgba($orange, 0.5);
}

// Variables

$max_books: 14;
$max_master_books: 4;
