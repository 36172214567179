section.feature {
  border-radius: 15px;
  background: $orange image-url('../../assets/images/banner_bg.png') no-repeat 100% 50% /
    cover;
  padding: 60px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  &:not(:first-child) {
    @include spacing('margin-top', 0.5);
  }
  @include desktop(false) {
    max-width: 636px;
    padding: 80px;
  }
  @include mobile() {
    padding: 60px 40px;
  }
  @media (max-width: 780px) {
  }
  @include phone() {
    padding: 50px 30px;
  }
  h3 {
    @include h_b(#fff);
    color: #fff !important;
    margin-bottom: 15px !important;
  }
  p {
    @include p(#fff);
    color: #fff !important;

    a.button {
      @include button($mint);
    }
  }
  .button {
    @include button($mint);
    margin-top: 25px;
  }
  .subscribe_form {
    @include form();
    padding-top: 30px;
    padding-bottom: 7px;

    fieldset {
      display: inline-block;
      vertical-align: middle;
      margin-top: 0;

      width: calc(100% - 50px);
      label {
        display: none;
      }
      input[type='email'] {
        border-color: #fff;

        border-radius: 10px 0 0 10px;
      }
    }
    .form_response {
      @include p();
      font-weight: bold;
      margin-bottom: 15px;

      a {
        text-decoration: underline;
      }
    }
    button[type='submit'] {
      display: inline-block;
      vertical-align: middle;
      padding-top: 17px;
      padding-bottom: 17px;
      padding-left: 15px;
      padding-right: 15px;
      box-sizing: border-box;

      border-radius: 100%;
      font-size: 0;
      width: 64px;
      height: 64px;
      margin: -7px -7px -7px -14px;
      background-repeat: no-repeat, no-repeat;
      background-size:
        30px auto,
        101% 100%;
      background-position:
        50% 50%,
        50% 50%;
      background-color: $mint;
      background-image: image-url('../../assets/images/layout/arrow_right.png'),
        image-url('../../assets/images/layout/mint_button_bg.png');

      &:hover {
        background-image: image-url('../../assets/images/layout/arrow_right.png'),
          image-url('../../assets/images/layout/orange_button_bg.png');
      }
    }
    &.complete {
      fieldset,
      button[type='submit'] {
        display: none;
      }
    }
  }
  &.book_13 {
    background-image: image-url('../../assets/images/banner_book_13_bg.png');
    background-size: 200% auto;
    background-position: 100% 50%;

    @include mobile() {
      background-size: auto 201%;
    }
    h3,
    p {
      color: $gray;
    }
    .button {
      @include button($gray);
    }
  }
  &.wide {
    max-width: 900px;

    @include phone_big(false) {
      padding-left: 50%;
    }
  }
  &.mint,
  &.subscribe {
    background: $mint;

    h3,
    p {
      color: $black !important;
    }
    .subscribe_form {
      max-width: 360px;

      button[type='submit']:not(:hover) {
        background-image: image-url('../../assets/images/layout/arrow_right_gray.png'),
          image-url('../../assets/images/layout/mint_button_bg.png');
      }
    }
  }
  &.mint {
    .button:not(:hover) {
      background: $foam image-url('../../assets/images/layout/transparent.gif') no-repeat
        0 0/100% 100%;
    }
  }
  + section.feature {
    margin-top: 20px;
  }
}
