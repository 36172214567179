.modal_logo {
  display: block;
  position: fixed;
  z-index: 301;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  transform: translateY(100%);
  transition:
    transform 0.5s,
    opacity 0.3s;
  visibility: hidden;
  pointer-events: none;

  .modal_windows {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    font-size: 0;
    scroll-snap-type: x mandatory;
    @include hide_scrollbars();
  }
  .modal_inner {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    white-space: normal;
    scroll-snap-align: center;
    scroll-snap-stop: always;

    + .modal_inner {
      margin-left: 30px;
    }
  }
  .modal_inner_background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .modal_content {
    position: relative;
    @include content();
    width: 100%;
    max-width: 1103px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 20px;
    margin: 40px auto;
    @include spacing('padding-bottom', 0.75);
    box-shadow: 0 80px 60px -60px rgba(#000, 0.2);

    @include desktop() {
      max-width: 1033px;
      margin: 35px auto;
    }
    @include mobile_menu() {
      margin: 0 auto;
    }
    .logo_full {
      border-radius: 20px 20px 0 0;
    }
  }

  &.visible {
    transform: translateY(0);
    visibility: visible;
    pointer-events: auto;

    + .modal_prev_button {
      transition-delay: 0s, 0s, 0.5s;
      transform: scale(1) rotate(180deg);
    }
    + * + .modal_next_button {
      transition-delay: 0s, 0s, 0.6s;
      transform: scale(1);
    }
  }
  &.delay {
    visibility: visible;
  }
}
.modal_prev_button,
.modal_next_button {
  position: fixed;
  z-index: 302;
  top: calc(50% - 30px);
  width: 61px;
  height: 61px;
  @include hide_text();
  border-radius: 100%;
  background: rgba(#000, 0.2) image-url('../../assets/images/layout/arrow_right.png')
    no-repeat 50% 50%/25px auto;
  transform: scale(0);
  transition:
    background 0.3s $easeCubic,
    box-shadow 0.3s $easeCubic,
    transform 0.3s $easeCubic;

  @media (max-width: 1033px) {
    display: none;
  }
  &.visible {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }
  @include hover() {
    @media (min-width: 1034px) {
      background-color: rgba(#000, 0.4);
    }
    @media (max-width: 1033px) {
      background-color: $gray_light;
    }
  }
}
.modal_prev_button {
  left: 40px;
  transform: scale(0) rotate(180deg);

  @include desktop() {
    left: 35px;
  }
  &.hide {
    display: none;
  }
}
.modal_next_button {
  right: 40px;

  @include desktop() {
    right: 35px;
  }
  &.hide {
    display: none;
  }
}

.modal_close_button {
  position: fixed;
  z-index: 412;
  cursor: pointer;
  top: 40px;
  right: 40px;
  width: 31px;
  height: 31px;
  @include hide_text();
  border-radius: 100%;
  background: $gray_light image-url('../../assets/images/icon/x_gray_little.png')
    no-repeat 50% 50%/9px auto;
  transform: scale(0);
  pointer-events: none;
  transition:
    opacity 0.3s $easeCubic,
    background 0.1s,
    transform 0.1s $easeCubic;

  @include desktop() {
    top: 35px;
    right: 35px;
  }
  @include laptop() {
  }
  @media (max-width: 1033px) {
    top: 10px;
    right: 10px;
  }
  &.visible {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }
  @include hover() {
    background-color: mix($gray, $gray_light, 20%);
  }
}
.modal_shade {
  position: fixed;
  z-index: 300;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(#000, 0.5);
  transform: translateY(-100%);
  transition: opacity 0.5s;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
  &.delay {
    transform: translateY(0);
    pointer-events: none;
  }
  &.overlapping {
    z-index: 400;
  }
}

.modal-delete {
  .prompt {
    font-size: 16px;
    font-family: 'Inter';
  }
}
