@mixin content() {
  @include logo_detail();

  h1,
  h2 {
    @include h_a();

    &:not(:first-child) {
      @include spacing('margin-top', 0.5);
    }
    &:not(:last-child) {
      @include spacing('margin-bottom', 0.5);
    }
  }
  h1 {
    color: $foam;

    @include phone() {
      text-align: center;
    }
  }
  h3 {
    @include h_b();

    &:not(:first-child) {
      @include spacing('margin-top', 0.75);
    }
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  h4 {
    @include h_c();

    &:not(:first-child) {
      margin-top: 30px;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  h5 {
    @include h_e();
  }
  h6 {
    @include label($foam);

    &:not(:first-child) {
      margin-top: 30px;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  p {
    @include p();

    &:not(.medium):not(.footnote) {
      line-height: 1.7;
    }
    a {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
    + p {
      margin-top: 20px;

      @include phone() {
        margin-top: 15px;
      }
    }
    &.medium {
      @include p_bigger();
    }
    &.footnote {
      @include p_little($gray_text);
    }
  }
  > ul,
  > ol {
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      position: relative;
      @include p();
      padding-left: 40px;

      @include phone() {
        padding-left: 30px;
      }
      + li {
        margin-top: 20px;
      }
    }
  }
  > ul {
    li {
      &:before {
        content: '';
        position: absolute;
        top: 12px;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 2.5px;
        background: $foam;

        @include mobile() {
          top: 10px;
        }
      }
    }
  }
  > ol {
    counter-reset: ol;

    li {
      counter-increment: ol;

      &:before {
        content: counter(ol);
        position: absolute;
        top: 0;
        left: 0;
        color: $foam;
        font-weight: bold;
      }
    }
  }
  hr {
    border: 0;
    border-top: solid 1px $gray_light;
    margin: 40px 0;
  }
  .feature_ribbon {
    background: $mint;
    padding: 20px 30px;
    @include p();
    font-weight: bold;

    &:not(:first-child) {
      margin-top: 30px;
    }
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    span {
      display: inline-block;
    }
  }
  .button_outer {
    &:not(:first-child) {
      margin-top: 30px;
    }
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  img {
    display: block;
    width: 100%;
    height: auto;

    &:not(:first-child) {
      margin-top: 30px;
    }
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  .faqs {
    &:not(:first-child) {
      @include spacing('margin-top', 0.5);
    }
    &:not(:last-child) {
      @include spacing('margin-bottom', 0.5);
    }
  }
}

$sticky_content_width: 335px;

.content_outer {
  @include spacing('padding-top', 0.75);

  &:not(:has(+ .content_outer)) {
    @include spacing('padding-bottom');
  }
  &.has_sticky_left,
  &.has_sticky_right {
    position: relative;
    $columns_width: 600px + $sticky_content_width;
    max-width: $columns_width + 120px;
    margin-left: auto;
    margin-right: auto;

    @include mobile(false) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @include spacing('padding-left');
      @include spacing('padding-right');
    }
    @include desktop() {
      max-width: $columns_width + 80px;
    }
    @include laptop() {
      max-width: $columns_width + 60px;
    }
    @include mobile() {
    }
    .sticky_content {
      transition:
        top 0.3s $easeCubic,
        bottom 0.3s $easeCubic; // For animation when scrolling up/down

      @include mobile(false) {
        width: 335px;
        position: sticky;
        top: 100px;
      }
      @include mobile() {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        @include spacing('margin-bottom', 0.75);
      }
      &.register {
        border-radius: 15px;
        background: $mint;
        padding: 60px;
        box-sizing: border-box;

        @include mobile() {
          position: relative;
          width: auto;
          top: auto;
          padding: 60px 50px;
          background-size: 200vw auto;
        }
        @include phone() {
          position: sticky;
          z-index: 10;
          bottom: 15px;
          width: 100%;
          margin-bottom: 0 !important;
          padding: 0 30px;
          background: none;
        }
        img {
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: 30px;
        }
        p {
          @include p();

          @include phone() {
            display: none;
          }
        }
        .button {
          @include button_big();
          margin-top: 30px;
          width: 100%;
          box-sizing: border-box;
          text-align: center;

          @include mobile() {
            @include phone(false) {
              width: auto;
              display: inline-block;
            }
          }
          @include phone() {
            margin-top: 0;
            padding-top: 21px;
            padding-bottom: 21px;
          }
          span {
            opacity: 0.5;
            text-transform: uppercase;
            margin-left: 5px;
          }
        }
        &.book_10 {
          background-image: image-url('../../assets/images/banner_book_13_bg.png');
        }
      }
    }
    .non_sticky_content {
      @include inner_width();
      @include content();

      @include mobile(false) {
        width: 600px;
      }
      @include mobile() {
        max-width: 600px;
      }
    }
    &:has(.sticky_content.register) {
      @include phone() {
        display: flex;
        flex-wrap: wrap-reverse;
      }
      .non_sticky_content {
        @include phone() {
          @include spacing('padding-bottom');
        }
      }
    }
    + .feature {
      margin-top: 0 !important;
    }
  }
  &.has_sticky_left {
    .non_sticky_content {
      @include mobile(false) {
        padding-right: 0 !important;
      }
    }
  }
  &.has_sticky_right {
    .non_sticky_content {
      @include mobile(false) {
        padding-left: 0 !important;
      }
    }
  }
  &:not(.has_sticky_left):not(.has_sticky_right) {
    @include content();
    @include clear();
  }
  &:not(.has_sticky_left):not(.has_sticky_right):not(.wide) {
    @include inner_width();
  }
  &.wide {
    @include inner_width_wide();
  }
  &.narrow {
    max-width: 400px !important;
  }
}
.brand_new_logo {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

// Left/right backgrounds for one-column pages

body {
  &.has_left_background,
  &.has_right_background {
    main {
      &:has(> figure) {
        display: flex;
        align-content: stretch;
      }
      > figure {
        position: sticky;
        z-index: 0;
        top: 0;
        width: 33.333%;
        background: $mint image-url('../../assets/images/banner_mint_bg_big.png')
          no-repeat 50% 50% / cover;
        background-attachment: fixed;

        @include mobile() {
          display: none;
        }
      }
      > :not(figure) {
        width: 100%;
        background: transparent;
        padding-bottom: 0 !important;

        @include mobile(false) {
          width: 66.667%;
        }
      }
      &:not(:has(+ .footer-ribbon)) > figure {
        border-radius: 0 0 30px 0;
      }
    }
  }
  &.has_left_background main {
    &:not(:has(+ .footer-ribbon)) > figure {
      border-radius: 0 0 0 30px;
    }
  }
  &.has_right_background main {
    > figure {
      order: 1;
    }
    > :not(figure) {
      @include mobile(false) {
        order: 0;
      }
    }
  }
}
