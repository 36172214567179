html {
  scroll-behavior: smooth; // :)
  scroll-padding: 170px; // :)
}
body.account {
  main > .content_outer:first-child {
    @include spacing('padding-bottom', 0.75);
  }
  .content_outer {
    > div[id] {
      + div[id] {
        @include spacing('padding-top');

        h3 {
          border-top: solid 1px $gray_light;
          margin-top: 60px;
          padding-top: 40px;
        }
      }
    }
    h3 {
      color: $foam;
    }
    .renew_button {
      @include button_big();
    }
  }
}
.account_form,
.password_form,
.profile_form {
  @include form();
}
.form_alert {
  font-weight: bold;
  background: mix($mint, #fff, 50%);
  padding: 20px;

  &:not(:first-child) {
    margin-top: 40px;
  }
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}
.profile_image_outer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0 40px 0;

  .upload-item {
    position: relative;
    width: 120px;
    margin-right: 20px;

    @include mobile() {
    }
    @include phone() {
      width: 100px;
    }
    .upload-preview {
      position: relative;
      padding-top: 100%;
      width: 100%;
    }
    .upload-bucket {
      // #drop-bucket
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10%;
      border-radius: 15px;
      box-sizing: border-box;
      border: solid 4px #f2f2f2;

      &.is-drag-over {
        border-color: $mint;
      }
    }
    &.not_empty {
      + label {
        display: none;
      }
    }
  }
  label {
    text-align: center;
    @include p();

    strong {
      @include button(#f2f2f2);
    }
    span {
      display: block;
      margin-top: 5px;
      color: #999;
    }
  }
  input[type='file'] {
    display: none;
  }
}

// Profile Images

.sort-bucket {
  margin-top: 20px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  &-header {
    margin-bottom: 10px;
  }
}
.sort-bucket-content {
  max-height: 30em;
  min-height: 15em;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 1em;
  border: 2px solid #e8eaeb;
  border-radius: 18px;
  background-color: #fff;

  .logo-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    .logo-item {
      margin: 0;

      &-figure {
        transition: border 0.3s; 

        @include hover() {
          border-color: mix($gray,$gray_light,20%);
        }
      }
    }
    &#sort-bucket-left {
      grid-template-columns: repeat(2, 1fr);

      .logo-item-figure {
        position: relative;

        &:after {
          content: 'Remove';
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 1;
          @include p($gray);
          font-weight: 600;
          background: $gray_light;
          line-height: 30px;
          padding: 0 12px;
          border-radius: 100px;
          transform: translate(-50%,-50%);
          opacity: 0;
          transition: opacity 0.3s;
        }
        @include hover() {
          &:after {
            opacity: 1;
          }
        }
      }
    }
    &#sort-bucket-right {
      
      .logo-item-figure {
        position: relative;

        &:after {
          content: 'Add';
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 1;
          @include p($gray);
          font-weight: 600;
          background: $gray_light;
          line-height: 30px;
          padding: 0 12px;
          border-radius: 100px;
          transform: translate(-50%,-50%);
          opacity: 0;
          transition: opacity 0.3s;
        }
        @include hover() {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media (min-width: 48.0625em) {
  .sort-bucket {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 10px);
  }
  .sort-bucket:first-child {
    padding-right: 2em;
  }
  .sort-bucket + .sort-bucket {
    padding-left: 20px;
  }
}
