$form-line-height: 1.6;
$form-field-padding: 10px;
$focus-color: $foam;
$text_color: $gray;
$warning_color: $red;
$text-inputs: 'input[type=color], input[type=date], input[type=datetime], input[type=datetime-local], input[type=email], input[type=month], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], input[type=week]';

::selection {
  background: rgba($focus-color, 0.33); /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: rgba($focus-color, 0.33); /* Gecko Browsers */
}

%field {
  @include p($text_color);
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 50vh;
  line-height: $form-line-height;
  outline: none;
  border: 0;
  border: solid 2px $gray_light;
  border-radius: 10px;
  background: #fff;
  padding: $form-field-padding #{$form-field-padding + 6px};
  box-sizing: border-box;
  transition:
    border 0.3s,
    box-shadow 0.3s;

  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;

  @include hover() {
    border: solid 2px mix($gray, $gray_light, 10%);
  }
  &:focus {
    border-color: $focus-color;

    + .placeholder,
    + * + .placeholder {
      opacity: 0.5;
    }
  }
  &::placeholder {
    color: $gray_mid;
  }
}
%label {
  display: block;
  @include p($gray_text);
  line-height: $form-line-height;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  transition: color 0.3s;
}
%placeholder {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  @include p($text_color);
  font-size: 20px;
  line-height: $form-line-height;
  padding: $form-field-padding 0;

  @include desktop() {
    font-size: 18px;
  }
  @include mobile() {
    font-size: 16px;
  }
}

@mixin form() {
  position: relative;

  .form_inner {
    transition: opacity 1s;
  }
  fieldset,
  .form-group {
    position: relative;

    legend {
      @include label($foam);
      margin-bottom: 15px;

      @include phone() {
      }
    }
    h3 {
      @include h_c();
    }
    label {
      @extend %label;
    }
    label.required::after {
      content: ' *';
      color: red;
    }
    .placeholder {
      @extend %placeholder;
    }
    &.notempty {
      input[type='date'] {
        color: $text_color;
      }
      label {
        opacity: 1;
        transform: translateY(-27px) scale(0.75);
      }
      .placeholder {
        opacity: 0 !important;
      }
      .error_message {
        opacity: 0;
        pointer-events: none;
      }
    }
    .error_message {
      @include p();
      //position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: inline-block;
      line-height: $form-line-height;
      color: $warning_color;
      text-align: left;
      padding: $form-field-padding 0;
      background: #fff;
      transition: opacity 0.3s;
      &.login_error {
        font-size: 12px;
        padding: 5px;
      }
    }
    &.error {
      #{$text-inputs},
      textarea,
      select {
        border-bottom-color: $warning_color;
        box-shadow: 0 1px 0 $warning_color;
      }
    }
    &.half {
      width: 50%;
      display: inline-block;
      vertical-align: top;
    }
    @for $i from 1 through 9 {
      &.width-#{$i * 10} {
        width: calc($i / 10);
        display: inline-block;
        vertical-align: top;

        @if $i <= 5 {
          @include phone() {
            width: calc($i / 5);
          }
        }
      }
    }
    &.width-50 {
      @include phone() {
        display: block;
      }
    }
    .password-container {
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 2;
        height: 20px;
        width: 20px;
        > svg {
          height: 20px;
          width: 20px;
        }
      }
      /* for Internet Explorer and Edge */
      input[type='password']::-ms-reveal,
      input[type='password']::-ms-clear {
        display: none;
      }

      /* for Chrome */
      input[type='password']::-webkit-credentials-auto-fill-button {
        visibility: hidden;
        position: absolute;
        right: 0;
      }
    }
  }
  .group,
  .form-group,
  fieldset {
    &:focus-within {
      > label {
        color: $gray;
      }
    }
  }
  // all text inputs
  #{$text-inputs} {
    @extend %field;
  }
  input[type='date'] {
    color: transparent;
  }
  select {
    @extend %field;
    background-image: image-url('../../assets/images/icon/arrow_down_gray.svg');
    background-position: calc(100% - 16px) 50%;
    background-repeat: no-repeat;
    background-size: 12px auto;
  }
  textarea {
    @extend %field;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    resize: none;

    @include desktop() {
    }
    @include mobile() {
    }
  }
  #{$text-inputs},
  textarea,
  select {
    &[data-validate*='required'] + .placeholder:after,
    &[data-validate*='required'] + * + .placeholder:after {
      content: ' *';
    }
    + p.footnote {
      margin-top: 15px;
    }
  }
  .checkbox_outer {
    margin: -10px;
    margin-top: 20px;
    font-size: 0;

    label {
      @include p($gray_mid);
      top: 0;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      padding: 10px 20px 10px 10px;
      padding-left: 32px + 10px;
      margin: 0;
      border-radius: 25px;
      pointer-events: auto;
      transition:
        color 0.3s,
        background 0.3s;

      a {
        color: inherit;
        text-decoration: underline;
        text-underline-offset: 2px;

        &:hover {
          color: $orange;
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: calc(50% - 10px);
        left: 10px;
        border-radius: 100%;
        border: solid 2px mix(#000, #fff, 20%);
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        transition: border 0.3s;
      }
      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 5px);
        left: 15px;
        border-radius: 100%;
        width: 10px;
        height: 10px;
        transform: scale(0);
        background-color: $gray;
        transition:
          transform 0.3s $easeCubic,
          background 0.3s;
      }
      @include hover() {
        color: $gray;
        background-color: $mint;

        &:before {
          border-color: $gray_mid;
        }
      }
      &:focus {
        color: $gray;

        &:before {
          border-color: $focus-color;
        }
      }
    }
    input[type='checkbox'],
    input[type='radio'] {
      display: none;

      &:checked + label {
        color: $text_color;

        &:before {
          border-color: mix(#000, #fff, 20%);
        }
        &:after {
          transform: scale(1);
          background: $gray;
        }
        &:hover:before {
          border-color: $gray_mid;
        }
      }
    }
    .error_message {
      padding-top: 0;
      padding-bottom: 10px;
    }
    + .checkbox_outer {
      margin-top: 0;
    }
    &.hug_top {
      margin-top: 18px;
    }
    &.hidden {
      display: none;
    }
    &.error label:before {
      border-color: $warning_color;
    }
  }
  label + .checkbox_outer {
    margin-top: 0;
  }

  .button_outer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;

    p {
      margin-bottom: 0 !important;

      a {
        @include p_little($gray_text);
        transition: color 0.3s;
        text-decoration: none !important;

        @include hover() {
          color: $orange;
        }
      }
    }
    label,
    button {
      cursor: pointer;
      @include button_big();
      width: 50%;
      text-align: center;
      box-sizing: border-box;

      &:first-child {
        @include phone_little() {
          width: 100%;
        }
      }
    }
    &:has(p) {
      justify-content: space-between;
    }
  }

  // all buttons
  button,
  input[type='submit'],
  input[type='button'] {
    @include button_big();
  }

  // submission states

  .ajax_response {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateY(20px);
    transition:
      opacity 0.5s,
      transition 0.5s $easeCubic;

    .ajax_response_inner {
      width: 100%;
      text-align: center;

      p {
        margin-bottom: 0;
      }
    }
  }
  > * {
    transition: opacity 0.3s;
  }
  &.processing {
    .form_inner {
      opacity: 0;
    }
    &:after {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &.complete {
    .form_inner {
      opacity: 0;
    }
    .ajax_response {
      z-index: 1;
      opacity: 1;
      transform: translateY(0);
    }
    &:after {
      transform: translateY(-20px);
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Grandfathered styles

.form-group {
  box-sizing: border-box;

  &-wrap--street-suite {
    .form-group {
      display: inline-block;
      vertical-align: top;

      &:first-child {
        width: 75%;
        padding-right: 0.5em;

        + .form-group {
          width: 25%;
          padding-left: 0.5em;
        }
      }
    }
  }

  &-wrap--city-state-zip {
    .form-group {
      display: inline-block;
      vertical-align: top;

      &:first-child {
        @include phone(false) {
          width: 40%;
          padding-right: 0.5em;
        }
        @include phone() {
          width: 100%;
        }
        + .form-group {
          width: 30%;
          padding-right: 0.5em;

          @include phone(false) {
            padding-left: 0.5em;
          }
          @include phone() {
            width: 60%;
          }
          + .form-group {
            width: 30%;
            padding-left: 0.5em;

            @include phone() {
              width: 40%;
            }
          }
        }
      }
    }
  }
  &-wrap--month-year-cvv .form-group {
    display: inline-block;
    vertical-align: top;

    &:first-child {
      width: 30%;
    }
    + .form-group {
      width: 35%;
      padding-left: 1em;
    }
  }

  &-half,
  &-wrap--name {
    .form-group {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      &:first-child {
        padding-right: 0.5em;
      }
      + .form-group {
        padding-left: 0.5em;
      }
    }
  }
}
