$color_c: $gray;

main.has_tabs {
  &[class*='tab_count'] > section {
    display: none;
  }
  > div > section {
    > .content_outer {
      padding-top: 45px;
      @include spacing('padding-bottom');
      @include inner_width_wide();

      @include phone() {
        padding-top: 35px;
      }
      .summary_text {
        &.content {
          display: flex;
          gap: 2px;
          p,
          a {
            color: $gray_text;
            margin-top: 0px;
            font-family: 'Inter', Helvetica, Arial, sans-serif;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 1.7;
            cursor: pointer;
          }
          .selected {
            text-decoration: underline;
          }
        }
        margin-bottom: 50px;

        @include phone() {
          margin-bottom: 40px;
        }
        h2,
        h3 {
          color: $foam;
          margin-bottom: 20px;

          @include mobile() {
          }
          @include phone() {
            margin-bottom: 10px;
          }
        }
        p {
          color: $gray_text;
        }
        p span {
          display: inline-block;
        }
        &:has(> .button) {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }
        .button {
          @include button_tiny();
          margin-top: -7px;
          margin-bottom: -7px;
        }
      }
    }
  }
}
.tabs {
  overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  @include hide_scrollbars();
  scroll-behavior: smooth;
  user-select: none;
  text-align: center;
  @include disable_text_selection();
  @include inner_width();
  max-width: none;
  padding: 0 !important;
  margin-top: -20px;
  margin-bottom: -1px;

  @include mobile() {
    margin-top: -15px;
  }
  .tabs_inner {
    font-size: 0;

    @include phone() {
      display: inline-block;
      text-align: center;
      padding: 0;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .tab {
    // label
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 80px;
    max-width: calc(100vw - 60px);
    overflow-x: hidden;
    text-overflow: ellipsis;
    @include p_article();
    font-weight: 500;
    line-height: 80px - 3px;
    padding: 0 20px;
    border-radius: 5px 5px 0 0;
    transition:
      color 0.2s,
      border 0.2s,
      background 0.2s,
      box-shadow 0.2s;

    @include desktop() {
      height: 70px;
      line-height: 70px - 2px;
    }
    @include mobile_menu() {
      height: 60px;
      line-height: 60px - 1px;
    }
    @include mobile() {
      height: 60px;
    }
    @include phone() {
      letter-spacing: 0;
      padding-left: 15px;
      padding-right: 15px;
    }
    @include hover() {
      background: rgba($mint, 0.5);
    }
    .number {
      margin-left: 5px;
      color: $foam;
      font-weight: 600;

      @include phone() {
        margin-left: 3px;
      }
    }
    &.unread:after {
      content: '';
      position: relative;
      display: inline-block;
      top: -13px;
      left: -2px;
      width: 8px;
      height: 8px;
      background: $red;
      border-radius: 100%;
      margin-right: -8px;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 20px;
      height: 4px;
      transform-origin: 0 100%;
      transform: scaleY(0);
      border-radius: 4px 4px 0 0;
      background: $mint image-url('../../assets/images/layout/mint_button_bg.png')
        no-repeat 50% 50%/101% 100%;
      transition: transform 0.2s $easeCubic;

      @include phone() {
        left: 15px;
        right: 15px;
      }
    }
    &.active:before {
      transform: scaleY(1);
    }
    // &:first-child {
    //   @include mobile(false) {
    //   }
    //   @include phone() {
    //     margin-left: 15px;
    //   }
    // }
    // &:last-child {
    //   @include mobile() {
    //   }
    //   @include phone() {
    //     margin-right: 15px;
    //   }
    // }
    &.isHidden {
      display: none;
    }
  }
}
.tabs_outer {
  position: sticky;
  z-index: 10;
  top: 0;
  font-size: 0;
  text-align: center;
  background: #fff;
  border-bottom: solid 1px $gray_light;
  transition: top 0.3s $easeCubic;

  &.overflow {
    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      @include spacing('width');
      transition: opacity 0.2s;
      pointer-events: none;

      @include phone() {
        content: none;
      }
    }
    @include phone(false) {
      .tabs_nav_left,
      .tabs_nav_right {
        position: absolute;
        z-index: 2;
        top: 50%;
        width: 40px;
        height: 40px;
        margin-top: -20px;
        border-radius: 100%;
        transform: rotate(-90deg);
        transition:
          background 0.2s,
          opacity 0.2s;

        &:hover,
        &:focus {
          background-color: rgba(#fff, 0.1);
        }
        &:active {
          background-color: rgba(#fff, 0.2);
        }
      }
      .tabs_nav_left {
        left: 0;
        transform: rotate(90deg);
      }
      .tabs_nav_right {
        right: 0;

        &:after {
          left: 13px;
          transform: rotate(135deg);
        }
      }
    }
  }
}
body.is_user .tabs_outer {
  @include mobile_menu() {
    top: 0 !important;
  }
}
@for $tab_count from 1 through 6 {
  main.has_tabs.tab_count-#{$tab_count} {
    [name='tab_nav'] {
      display: none;

      &:checked {
        $sel: '+';
        @if $tab_count > 1 {
          @for $i from 1 through $tab_count {
            $sel: $sel + ' * +';
          }
        }
        $sel: $sel;

        #{$sel} section {
          display: block;
        }
      }
    }
    @for $i from 1 through $tab_count {
      $sel: '+';
      $sel_b: '';
      @if $i > 1 {
        @for $j from 2 through $i {
          $sel: $sel + ' * +';
        }
        @for $j from 2 through $i {
          $sel_b: $sel_b + ' + [name="tab_nav"]';
        }
      }

      h1 #{$sel} [name='tab_nav']:checked,
      [name='tab_nav']#{$sel_b}:checked {
        $sel: '+';
        @if $i < $tab_count {
          @for $k from 1 through $tab_count - $i {
            $sel: $sel + ' * +';
          }
        }
        #{$sel} .tabs_outer label:nth-child(#{$i}) {
          color: $gray;

          &:before {
            transform: scaleY(1);
          }
        }
      }
    }
  }
}
