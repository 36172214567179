html {
  &:has(body.news) {
    scroll-padding: 80px - 1px;

    @include desktop() {
      scroll-padding: 70px - 1px;
    }
    @include mobile_menu() {
      scroll-padding: 60px - 1px;
    }
  }
  &:has(.is_up_toggle:checked) {
    // if the header is shown
    scroll-padding: 80px + 80px - 1px;

    @include desktop() {
      scroll-padding: 70px + 70px - 1px;
    }
    @include mobile_menu() {
      scroll-padding: 60px + 60px - 1px;
    }
  }
}
body.news {
  main {
    padding-top: 60px;

    @include mobile() {
      padding-top: 40px;
    }
  }
  .tabs_outer {
    .tabs {
      margin-top: 0 !important;
    }
  }
  .content_outer {
    padding-top: 0;
    @include spacing('padding-bottom');

    .category_heading {
      width: 100%;
      border-top: solid 1px $gray_light;
      @include spacing('padding-top', 0.5);
      @include spacing('padding-bottom', 0.5);
      margin-top: -1px;

      @include phone_big(false) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      h2 {
        color: $foam !important;
        margin: 0 !important;
      }
      a {
        @include button_tiny();

        @include phone_big() {
          margin-top: 15px;
        }
      }
    }
  }
}
.content_outer:not(.has_sticky_left):not(.has_sticky_right) {
  .article_group_outer:not(:first-child) {
    margin-top: 60px;

    @include mobile() {
      margin-top: 40px;
    }
  }
  .article {
    position: relative;
    padding: 0;
    transition: top 0.3s $easeCubic;

    .image {
      // figure
      width: 100%;
      margin-bottom: 30px;

      @include phone() {
        margin-bottom: 15px;
      }
      img {
        width: 100%;
        height: auto;
        border-radius: 15px;
        overflow: hidden;
      }
      @include hover() {
        + .text h4 {
          color: $orange;
        }
      }
    }
    .text {
      .category {
        @include label($foam);
      }
      p[itemprop='headline'] {
        @include p_bigger();
        font-weight: 500;
        line-height: 1.4;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        transition: color 0.3s;

        @include hover() {
          color: $orange;
        }
        a[href] {
          color: $gray;
          text-decoration: none;
        }
      }
      .caption {
        @include p_little($gray_text);
      }
      p[itemprop='description'] {
        margin-top: 7px;

        @include mobile(false) {
        }
        @include phone() {
          @include p_little();
        }
        a {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          @include hide_text();
        }
      }
    }
  }
  .article_group_a {
    position: relative;
    font-size: 0;

    > .article {
      // Big one

      @include mobile(false) {
        position: sticky;
        display: inline-block;
        vertical-align: top;
        top: 60px;
        width: calc(66.667% - 20px);
        margin-right: 60px;
      }
      .image {
        @include mobile(false) {
          margin-bottom: 30px;
        }
      }
      p[itemprop='headline'] {
        @include h_c();

        @include mobile(false) {
          margin-bottom: 10px !important;
        }
      }
      .caption {
        @include mobile(false) {
          @include p($gray_text);
        }
      }
      p[itemprop='description'] {
        @include mobile(false) {
          @include p_bigger();
          margin-top: 9px;
        }
      }
    }
    .side_articles {
      @include mobile(false) {
        display: inline-block;
        vertical-align: top;
        width: calc(33.333% - 40px);
      }
      @include mobile() {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      @include mobile() {
        margin-top: 60px;
      }
      @include phone_little() {
        margin-top: 0;
      }
      .article {
        @include mobile() {
          display: inline-block;
          vertical-align: top;
          width: calc(50% - 25px);
        }
        @include phone() {
          width: calc(50% - 15px);
        }
        @include phone_little() {
          width: 100%;
          margin-top: 40px;
        }
        + .article {
          @include mobile(false) {
            margin-top: 60px;
          }
        }
        &:first-child {
          @include mobile() {
            width: 100%;

            @include phone_little(false) {
              margin-bottom: 60px;
            }
          }
          @include phone_little() {
          }
          .image,
          .text {
            @include mobile() {
              display: inline-block;
              vertical-align: top;
              width: calc(50% - 25px);

              @include phone_little(false) {
                margin-bottom: 0;
              }
            }
            @include phone() {
              width: calc(50% - 15px);
            }
            @include phone_little() {
              width: 100%;
            }
          }
          .text {
            @include mobile() {
              margin-left: 50px;
            }
            @include phone() {
              margin-left: 30px;
            }
            @include phone_little() {
              margin-left: 0;
            }
          }
        }
      }
    }
    + .article_group_b {
      @include spacing('margin-top', 0.75);

      @include phone_little() {
        margin-top: 30px;
      }
    }
  }
  .article_group_b {
    display: flex;
    flex-wrap: wrap;

    .article {
      width: calc(33.333% - 40px);

      @include mobile() {
        width: calc(50% - 25px);
        margin-bottom: 60px;
      }
      @include phone() {
        width: calc(50% - 15px);
      }
      @include phone_little() {
        width: 100%;
      }
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        @include mobile(false) {
          margin-bottom: 60px;
        }
      }
      &:nth-child(2),
      &:nth-child(5),
      &:nth-child(3),
      &:nth-child(6) {
        @include mobile(false) {
          margin-left: 60px;
        }
      }
      &:nth-child(even) {
        @include mobile() {
          margin-left: 50px;
        }
        @include phone() {
          margin-left: 30px;
        }
        @include phone_little() {
          margin-left: 0;
        }
      }
    }
  }
  .news_pagination_outer {
    margin-top: 60px;

    @include desktop() {
      margin-top: 50px;
    }
    @include mobile_menu() {
      margin-top: 40px;
    }
  }
}
main.has_tabs
  .content_outer:not(.has_sticky_left):not(.has_sticky_right)
  .article_group_a
  > .article {
  // Big one

  @include mobile(false) {
    top: 80px + 45px;

    @include desktop() {
      top: 70px + 45px;
    }
    @include mobile_menu() {
      top: 60px + 45px;
    }
  }
}
