.gdpr_disclaimer {
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  left: 20px;
  right: 20px;
  box-sizing: border-box;
  @include p_little();
  padding: 18px 25px;
  background: #fff;
  border-radius: 10px;
  box-shadow:
    0 0 2px rgba(#000, 0.05),
    0 5px 20px rgba(#000, 0.05),
    0 40px 80px rgba(#000, 0.1);
  transition: transform 0.3s $easeInCubic;

  @include phone() {
    bottom: 10px;
    left: 10px;
    right: 10px;
    padding: 16px 20px;
  }
  a {
    display: inline-block;
    font-weight: 600;
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-color: $foam;
    text-decoration-thickness: 2px;
    transition:
      color 0.3s,
      text-decoration 0.3s;

    @include hover() {
      text-decoration-color: $black;
    }
  }
  .close {
    position: relative;
    float: right;
    width: 61px;
    height: 61px;
    margin: -18px -25px -18px 0;
    border-radius: 100%;
    box-shadow: inset 0 0 0 10px #fff;
    transition: background 0.3s;

    @include desktop() {
      width: 58px;
      height: 58px;
    }
    @include laptop() {
      width: 40px;
      height: 40px;
      box-shadow: inset 0 0 0 5px #fff;
    }
    @include phone() {
      margin: -16px -20px 0 0;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: calc(50% - 8px);
      width: 16px;
      height: 0;
      border-top: solid 1.5px mix($gray, #fff, 50%);
      transition: border 0.3s;

      @include laptop() {
        left: calc(50% - 6px);
        width: 12px;
      }
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    @include hover() {
      background: rgba($gray, 0.1);

      &:before,
      &:after {
        border-top-color: $gray;
      }
    }
  }
}
.gdpr_disclaimer_toggle {
  display: none;

  &:checked + .gdpr_disclaimer {
    transform: translateY(calc(100% + 40px));

    @include phone() {
      transform: translateY(calc(100% + 30px));
    }
  }
}
.footer-ribbon {
  position: relative;
  z-index: 1;
  scroll-snap-align: end;
  color: #fff;
  text-align: center;
  background: image-url('../../assets/images/banner_bg.png') no-repeat 50% 50% / cover;
  padding: 30px 15px;

  @include phone() {
    background-size: 200vw auto;
    background-position: 0 50%;
  }
  p {
    display: inline-block;
    vertical-align: middle;
    @include p_big(#fff);
    line-height: 1.2;
    margin: 10px 15px;

    span {
      display: inline-block;
    }
  }
  .button {
    @include button($mint);
    margin: 10px 15px;
  }
}
.site-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  @include p_little(#fff);
  @include spacing('padding-top', 0.5);
  padding-bottom: 30px;
  @include spacing('padding-left');
  @include spacing('padding-right');

  @include phone_big() {
    text-align: center;
  }
  .site-footer-container {
    @include mobile(false) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  nav {
    @include mobile(false) {
      flex-basis: 67%;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        @include phone_big() {
          display: inline-block;
          padding: 0 10px;
        }
        a {
          transition: color 0.3s;

          @include mobile() {
            padding: 5px 0;
            display: inline-block;
          }
          @include hover() {
            color: $mint;
          }
        }
        @include mobile(false) {
          display: inline-block;
        }
        + li {
          @include mobile(false) {
            margin-left: 30px;

            @include laptop() {
              margin-left: 20px;
            }
          }
        }
      }
    }
    &:not(.site-footer-details) {
      a {
        @include p(rgba(#fff, 0.5));
        transition: color 0.3s;

        @include hover() {
          color: #fff;
        }
      }
    }
  }
}
body.is_user .site-footer {
  @include mobile_menu() {
    padding-bottom: 30px + 66px;
  }
}

.site-footer-social {
  color: #93979a;

  @include mobile(false) {
    flex-basis: 33%;
    text-align: right;
  }
  p {
    display: inline-block;
    vertical-align: middle;
    text-transform: lowercase;
    letter-spacing: 0.05em;
    margin: 0;
  }
  ul {
    display: inline-block;
    vertical-align: middle;
    list-style: none;
    padding: 0;

    @include mobile(false) {
      margin: -15px -15px 0 0;
    }
    @include mobile() {
      margin-top: 20px;
      margin-left: -18px;
    }
    @include phone() {
      margin-left: 0;
      margin-right: 0;
    }
    li {
      display: inline-block;

      a {
        text-align: start;
        padding: 15px;

        &:before {
          opacity: 0.5;
          transition:
            background 0.3s,
            opacity 0.3s;
        }
        @include hover() {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
}
nav.site-footer-details {
  // Legal
  position: relative;
  z-index: 4;
  color: $gray_text;
  font-size: 12px;
  font-family: $paragraph_font;
  margin-top: 40px;
  flex-basis: 100%;

  @include mobile() {
    margin-top: 20px;
    font-size: 10px;
  }
}

.icon-social {
  position: relative;
  display: inline-block;
  padding: 0;
  height: 20px;
  width: 20px;
  @include hide_text();
  transition: background 0.3s;
  border-radius: 100%;

  @include phone_big() {
  }
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
    background-size: contain;

    @include phone_big() {
    }
  }
  @include hover() {
    background: rgba(#fff, 0.1);
  }
}

.modal,
.feedback {
  display: none;
}
.icon-social.icon-twitter::before {
  background-image: image-url('../../assets/images/social/twitter.svg');
}
.icon-social.icon-twitter--dark::before {
  background-image: url(https://www.logolounge.com/assets/img/icons/icon-social-twitter-dark.svg);
}
.icon-social.icon-pinterest::before {
  background-image: image-url('../../assets/images/social/pinterest.svg');
}
.icon-social.icon-pinterest--dark::before {
  background-image: url(https://www.logolounge.com/assets/img/icons/icon-social-pinterest-dark.svg);
}
.icon-social.icon-instagram::before {
  background-image: image-url('../../assets/images/social/instagram.svg');
}
.icon-social.icon-instagram--dark::before {
  background-image: url(https://www.logolounge.com/assets/img/icons/icon-social-instagram-dark.svg);
}
.icon-social.icon-dribbble::before {
  background-image: url(https://www.logolounge.com/assets/img/icons/icon-social-dribbble.svg);
}
.icon-social.icon-dribbble--dark::before {
  background-image: url(https://www.logolounge.com/assets/img/icons/icon-social-dribbble-dark.svg);
}
.icon-social.icon-facebook::before {
  background-image: image-url('../../assets/images/social/facebook.svg');
}
.icon-social.icon-facebook--dark::before {
  background-image: url(https://www.logolounge.com/assets/img/icons/icon-social-facebook-dark.svg);
}
.icon-social.icon-behance::before {
  background-image: url(https://www.logolounge.com/assets/img/icons/icon-social-behance.svg);
}
.icon-social.icon-behance--dark::before {
  background-image: url(https://www.logolounge.com/assets/img/icons/icon-social-behance-dark.svg);
}
.icon-social.icon-linkedin::before {
  background-image: image-url('../../assets/images/social/linkedin.svg');
}
.icon-social.icon-linkedin--dark::before {
  background-image: url(https://www.logolounge.com/assets/img/icons/icon-social-linkedin-dark.svg);
}

.icon-arrow {
  height: 1.5em;
  width: 1.5em;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.icon-arrow:hover {
  opacity: 0.5;
}
.icon-arrow--left {
  background-image: url(https://www.logolounge.com/assets/img/icon-nav-left-light.svg);
}
.icon-arrow--right {
  background-image: url(https://www.logolounge.com/assets/img/icon-nav-right-light.svg);
}
.icon--large {
  font-size: 1.5em;
}
.icon-award {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  padding: 0;
  height: 2.25em;
  width: 2.25em;
}
.icon-award.icon-award--small {
  height: 1.25em;
  width: 1.25em;
}
.icon-gear {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  width: 0.6875em;
  height: 0.75em;
  background-image: url(https://www.logolounge.com/assets/img/icon-gear.png);
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}
.icon-narrow--everything {
  background-image: url(https://www.logolounge.com/assets/img/icon-narrow-everything-11.svg);
  height: 1.75em;
  width: 1.75em;
}
.icon-narrow--everything.active {
  background-image: url(https://www.logolounge.com/assets/img/icon-narrow-everything-active-11.svg);
}
.icon-narrow--collections {
  background-image: url(https://www.logolounge.com/assets/img/icon-narrow-collections-12.svg);
  height: 1.25em;
  width: 1.25em;
}
.icon-narrow--collections.active {
  background-image: url(https://www.logolounge.com/assets/img/icon-narrow-collections-active-12.svg);
}
.icon-narrow--ranked {
  background-image: url(https://www.logolounge.com/assets/img/icon-narrow-ranked-13.svg);
  height: 1em;
  width: 1em;
}
.icon-narrow--ranked.active {
  background-image: url(https://www.logolounge.com/assets/img/icon-narrow-ranked-active-13.svg);
}
.icon-narrow--books {
  background-image: url(https://www.logolounge.com/assets/img/icon-narrow-books-14.svg);
  height: 0.75em;
  width: 0.75em;
}
.icon-narrow--books.active {
  background-image: url(https://www.logolounge.com/assets/img/icon-narrow-books-active-14.svg);
}
.icon-type--typographic {
  background-image: url(https://www.logolounge.com/assets/img/icon-type-typographic-15.svg);
  height: 1.5em;
  width: 2em;
}
.icon-type--typographic.active {
  background-image: url(https://www.logolounge.com/assets/img/icon-type-typographic-active-15.svg);
}
.icon-type--symbol {
  background-image: url(https://www.logolounge.com/assets/img/icon-type-symbol-16.svg);
  height: 1.375em;
  width: 1.375em;
}
.icon-type--symbol.active {
  background-image: url(https://www.logolounge.com/assets/img/icon-type-symbol-active-16.svg);
}
.icon-type--combo {
  background-image: url(https://www.logolounge.com/assets/img/icon-type-combo-17.svg);
  height: 1.75em;
  width: 1.75em;
}
.icon-type--combo.active {
  background-image: url(https://www.logolounge.com/assets/img/icon-type-combo-active-17.svg);
}
.leap-join {
  padding-top: 2em;
}
@media (min-width: 48.0625em) {
  .leap-join-form,
  .leap-join-info {
    position: relative;
    display: inline-block;
    vertical-align: top;
  }
  .leap-join-info {
    width: 60%;
    padding-right: 2em;
  }
  .leap-join-form {
    width: 40%;
    padding-left: 2em;
  }
}
.logo-item {
  position: relative;
  margin-bottom: 4%;
}
.logo-item-figure {
  position: relative;
  border-radius: 16px;
  padding-top: 100%;
  height: 0;
  background-color: #fff;
  border: 0;
  border: 2px solid #e8eaeb;
}
.logo-item-figure-content {
  position: absolute;
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  top: 1em;
  right: 1em;
  bottom: 1em;
  left: 1em;
}
.logo-item-figure-content img {
  position: relative;
  max-height: 100%;
  display: block;
  margin: 0 auto;
  object-fit: contain;
}
.logo-item a[href] img:hover {
  opacity: 0.5;
}
.logo-item .logo-item-award {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  padding: 0.5em 0.5em 0 0;
  background-color: #fff;
  border-radius: 4px 16px 0 3px;
  white-space: nowrap;
}
.logo-item .logo-item-award .icon-award {
  height: 1.5em;
  width: 1.5em;
  display: inline-block;
}
.logo-item .logo-item-award .icon-award + .icon-award {
  margin-left: 0.25em;
}
.logo-item.logo-item--small .logo-item-award {
  padding-right: 0.25em;
  padding-bottom: 0.25em;
  border-radius: 3px 0 4px;
}
.logo-item.logo-item--small .logo-item-award .icon-award {
  height: 1em;
  width: 1em;
}
.logo-item.logo-item--small .logo-item-figure-content {
  top: 0.5em;
  right: 0.5em;
  bottom: 0.5em;
  left: 0.5em;
}
.logo-item--has-actions .logo-item-figure {
  padding-bottom: 2em;
}
.logo-item--has-actions .logo-item-figure .logo-item-figure-content {
  bottom: 2em;
}
.logo-item-actions {
  position: absolute;
  z-index: 10;
  right: 0.5em;
  margin-top: -1.75em;
}
.logo-item-actions ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;
}
.logo-item-actions li {
  position: relative;
  display: inline-block;
  text-align: left;
}
.logo-item-actions li + li {
  margin-left: 0.5em;
}
.logo-item-actions span {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.75em;
  color: #93979a;
  margin-top: 0.1em;
}
.no-touchevents .logo-item-actions {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.icon,
.icon-add,
.icon-add-to-collection,
.icon-add-to-trends,
.icon-view-details {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  z-index: 10;
  -webkit-background-size: contain;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: -999999em;
  width: 1em;
  height: 1em;
  overflow: hidden;
  font-size: 16px;
}
